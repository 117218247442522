import React, { Component } from 'react'
import ModalWrapper from './ModalWrapper'
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { ReactComponent as PasswordChanged } from "../../../assests/img/Login/password-changed-successfully.svg";
import { Link } from 'react-router-dom';
import { ReactComponent as TokenInvalid } from "../../../assests/img/Login/token-invalid.svg";
import { connect } from 'react-redux';
import { SignUpMessageClear } from 'Redux/auth/authActions';


class RedemptionSuccesfull extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  handleOK = () =>{
    this.props.SignUpMessageClear();
  }

  render() {
    const { lang, t ,responseMessage} = this.props;
    return (
      <div>
            <ModalWrapper show={this.props.show} >
            <div className="bg-white muted-color mx-auto px-5 LoginModel width-35" >
            <div className="text-center mt-5">  
              <div className='redempton-pop'>
                {
                  this.props.redeemValid ? <PasswordChanged/> : <TokenInvalid/>  
                }
              </div>
              {
                this.props.redeemValid ? <>
                  <h5 className='fw-bold mt-3'>{t("Redeemed Successfully")}</h5>
                  <p>{t("You have redeemed your Reward no")}: {this.props.giftCardNo} {t("and points have been added to your account.")}</p>
                  {
                    !this.props.selectedLogin?
                    <p className='mt-4'>{t(`A verification email has been sent to`)} {`${this.props.email}`}{t(`, Please check your mailbox to verify your account`)}</p>:null
                  }
                </> : 
                <h5 className='fw-bold mt-3'>{t(responseMessage || "Your card has been already redeemed")}</h5>
              }
            </div>
              <div className="text-center mb-2 mt-3">
                <Link to="/">
                  <button className="btn btn-sky text-light w-100 " onClick={this.props.redeemPopShow}>
                    {t("Start Shopping")}
                  </button>
                </Link>
              <br />
            </div>
              
          </div>
            {/* <div className="bg-white  LoginModel px-4">
                    <div className="d-flex justify-content-center align-items-center flex-column mt-3">
                        {
                            this.props.redeemValid?
                            <>
                                <h3>Redeemed Successfully</h3>
                        <p>You have redeemed your Gift card no: {this.props.giftCardNo} and points have been added to your account.</p>
                            </>:   <>
                            <h3>Insufficent balance </h3>
                            </>
                        }
                        <h3>Redeemed Successfully</h3>
                        <p>You have redeemed your Gift card no: {this.props.giftCardNo} and points have been added to your account.</p>
                        <button onClick={()=> {
                            this.props.redeemPopShow();
                            this.props.history.push("/");
                        }} className="btn btn-primary w-100">Ok</button>
                    </div>
            </div> */}
            </ModalWrapper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email:state?.auth?.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignUpMessageClear : () => dispatch(SignUpMessageClear())
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(RedemptionSuccesfull))