/* eslint-disable array-callback-return */
import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import {
    FETCH_PRODUCTS_LIST_FAILURE, FETCH_PRODUCTS_LIST_REQUEST, FETCH_PRODUCTS_LIST_SUCCESS,
    FETCH_PRODUCT_DETAILS_FAILURE, FETCH_PRODUCT_DETAILS_REQUEST, FETCH_PRODUCT_DETAILS_SUCCESS,
    FETCH_VARIANTS_FAILURE, FETCH_VARIANTS_REQUEST, FETCH_VARIANTS_SUCCESS, SORT_MERCHANDISE_CARDS, FETCH_MERCHANDISE_TERMS
} from "./merchandiseTypes"

import { REACT_APP_baseUrl } from 'utils/app-constants';

export const fetchProductsListRequest = () => {
    return {
        type: FETCH_PRODUCTS_LIST_REQUEST,
    }
}

export const fetchProductsListSuccess = (categories, prodItem,featuredProducts) => {
    return {
        type: FETCH_PRODUCTS_LIST_SUCCESS,
        categories: categories,
        prodItem: prodItem,
        featuredProducts:featuredProducts
    }
}

export const fetchProductsListFailure = error => {
    return {
        type: FETCH_PRODUCTS_LIST_FAILURE,
        payload: error
    }
}

export const fetchProductsDetailsRequest = () => {
    return {
        type: FETCH_PRODUCT_DETAILS_REQUEST,
    }
}

export const fetchProductsDetailsSuccess = data => {
    return {
        type: FETCH_PRODUCT_DETAILS_SUCCESS,
        payload: data
    }
}

export const fetchProductsDetailsFailure = error => {
    return {
        type: FETCH_PRODUCT_DETAILS_FAILURE,
        payload: error
    }
}


// const cacheHeaders = () => {
//     return {
//         headers: {
//             'Content-Type': 'application/json',
//             'Cache-Control': 'no-cache, no-store, must-revalidate',
//             'Expires': '0'
//         }
//     }
// }

export const fetchProductsList = (payload) => {
    return (dispatch) => {
        dispatch({type:FETCH_PRODUCTS_LIST_REQUEST})
        dispatch(fetchProductsListRequest);
        axios.post(`${REACT_APP_baseUrl}/merchant_products/get_products`,
            payload,
            {
                params: {
                    "secret_key": secretKeyGenerator(payload)
                }
            })
            .then(response => {
                if(response.data.code === 200){
                    let prodItem = [];
                    let featuredProducts = [];
                    let categories = response.data?.data?.categories;
                    
                    response.data.data?.categories.map(item => {
                        item.products.map(val => {
                            prodItem.push(val)
                            val.featured_flag && featuredProducts.push(val)
                        })
                    })
                    prodItem.sort((a,b) => a.sort_order - b.sort_order)
                    dispatch(fetchProductsListSuccess(
                        categories,
                        prodItem,
                        featuredProducts
                    ))
                }
                else{
                    dispatch(fetchProductsListFailure(response.data?.message))
                }

               
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchProductsListFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchProductsDetails = (payload) => {
    return (dispatch) => {
        dispatch({type:FETCH_PRODUCT_DETAILS_REQUEST})
        dispatch(fetchProductsDetailsRequest);
        const prodDetailsResponse = axios.post(`${REACT_APP_baseUrl}/merchant_products/product_details`,
            payload,
            {
                params: {
                    "secret_key": secretKeyGenerator(payload)
                }
            })
        const prodImagesResponse = axios.post(`${REACT_APP_baseUrl}/merchant_products/product_images`,
            payload,
            {
                params: {
                    "secret_key": secretKeyGenerator(payload)
                }
            })
        Promise.all([prodDetailsResponse, prodImagesResponse])
            .then(([prodDetails, prodImages]) => {
                const prodDetailsOrder = prodDetails.data?.data.product
                const prodImagesOrder = prodImages.data?.data?.product_images;
                const variants = prodDetails.data?.data?.product.prices?.filter(variant => variant.variants === null ? false : true )
                // const variantsData = {}
                    dispatch(fetchProductsDetailsSuccess({
                        prodDetails: prodDetailsOrder,
                        prodImages: prodImagesOrder,
                        variants: variants,
                    }))
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchProductsDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchVariantsRequest = () => {
    return {
        type: FETCH_VARIANTS_REQUEST,
    }
}

export const fetchVariantsSuccess = data => {
    return {
        type: FETCH_VARIANTS_SUCCESS,
        payload: data
    }
}

export const fetchVariantsFailure = error => {
    return {
        type: FETCH_VARIANTS_FAILURE,
        payload: error
    }
}

// export const fetchVariants = (payload) => {
//     return (dispatch) => {
//         dispatch(fetchVariantsRequest);
//         axios.post('https://testapimeritmarketplace.meritincentives.com/api/v1/merchant_products/product_variants',
//             payload,
//             {
//                 params: {
//                     "secret_key": secretKeyGenerator(payload)
//                 }
//             }).then(response => {
//              
//                 response.data.data.variants[0].selected = response.data.data.variants[0].values[0]
//                 response.data.data.variants[1].selected = response.data.data.variants[1].values[0]
//                 const prodVariants = response.data.data;
//                 dispatch(fetchVariantsSuccess(prodVariants))
//             })
//             .catch(error => {
//                 const errorMsg = error
//                 dispatch(fetchVariantsFailure("failed"))
//             })
//     }
// }
// export const updateVariants = (variants,variant) => {
//     return (dispatch) => {
//         variants.variants.forEach(element => {
//             if(element.variant_name==variant.name){
//                 element.selected=variant.title;
//             }
//         });
//         dispatch(fetchVariantsSuccess(variants));
//     }
// }


export const sortMerchandiseCards = (data,sortBy,categoryName,onlyCategory,onlyPointsFilter) => (dispatch) => {
    let sortedCards = undefined;
    let defaultVal = [...data];
    if(onlyCategory){
        
        
        let categorizedCard = [];
        let filteredCards
        if(categoryName === "All"){
            filteredCards = defaultVal
            filteredCards.forEach(item => item.products.forEach(e => categorizedCard.push(e)))
            sortedCards = categorizedCard
        }
        else {
            filteredCards = data.filter(item => item.name === categoryName)
            if(filteredCards.length === 0){sortedCards = categorizedCard}
            else{
                filteredCards.forEach(item => categorizedCard.push(item.products))
            
                sortedCards = categorizedCard[0]
        
       }  
        } 

        
    }
    else if(onlyPointsFilter){
        
        let from = onlyPointsFilter.from || 0; 
        let to = onlyPointsFilter.to || 1000000000;
        
       sortedCards = data.filter(item => item.retail_price >=from && item.retail_price<=to)
      
    }
    else if(sortBy === "Points"){
       
        sortedCards = defaultVal.sort((a,b)=> a.retail_price - b.retail_price);

    }
    else{
      
        sortedCards = defaultVal;
    }
    
    return dispatch({
        type: SORT_MERCHANDISE_CARDS,
        payload:{
            cards : sortedCards,
            sortBy:sortBy,
            categoryName: categoryName
        }
    })
}
export const fetchMerchandiseTerms = (payload) => {
    return (dispatch) => {
        axios.post(`${REACT_APP_baseUrl}/merchant_products/product_terms`,
            payload,
            {
                params: {
                    "secret_key": secretKeyGenerator(payload)
                }
            })
            .then(response  => {    
               
                 return dispatch({
                     type: FETCH_MERCHANDISE_TERMS,
                     payload:{
                         merchandiseTerms : response.data
                     }
                 })
             })
             .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
            })
    }
}
