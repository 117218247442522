import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import searchIcn from '../../assests/img/header/icon-only-search.png'
import SearchIcon from "../../assests/img/header/icon-search.svg"

class SearchBar extends Component {
    // constructor(props) {
    //     super(props);
    // }
    
    render() { 
        const {sectionName,dropdownFilter,setDropdownFilter} = this.props
        const {t} = this.props
        return ( 
            <div className='d-flex w-100 mx-md-4 mx-0 border' >
                {this.props.dropdownItems &&
                 <div className="p-0" style={{width:'fit-content !important'}}>
                    <button className="btn dropdown-toggle fw-bold h-100 color-skyblue border-color-skyblue d-flex justify-content-between align-items-center" type="button" data-toggle="dropdown">{t(dropdownFilter)}
                    <span className="caret"></span></button>
                    <ul className="dropdown-menu">
                        {this.props.dropdownItems.map(item=>(
                            <li 
                                key={item} 
                                className=" cursor-pointer dropdown-item"
                                onClick={e=>setDropdownFilter(item)}
                            >
                                {t(item)}
                            </li>
                        ))}
                    </ul>
                </div>}
                
                <div className="d-flex p-0 flex-grow-1">
                    <input 
                        className="w-100 wallet-search flex-grow-1 border-0 p-md-3 p-2 outline-color-skyblue"
                        type="text" name="searchInput"
                        placeholder={`${t("Search")} ${t(sectionName)}`}
                        onChange={(e)=>{this.props.onChange(e.target.value)}}
                    />
                </div>
                <button className="d-md-block d-none p-0 primary-btn border-0 " style={{width:"4rem"}}>
                    <img src={SearchIcon} className="img-fluid"  alt="icon"/>
                </button>
                <button className="d-md-none d-block p-0 border-0 bg-white" style={{width:"3rem"}}>
                    <img src={searchIcn} className="img-fluid h-100 icon-white"  alt=""/>
                </button>
            </div>
         );
    }
}
 
export default withTranslation()(SearchBar);