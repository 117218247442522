import React, { Component } from 'react';
import ProductOffersCard from '../shared/ProductOffersCard';
import { Dropdown } from 'react-bootstrap';
import DropdownToggle from 'react-bootstrap/esm/DropdownToggle';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import DropdownItem from 'react-bootstrap/esm/DropdownItem';
import Breadcrumbs from '../shared/Breadcrumbs';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { sortOfferCards } from '../../Redux/offers/offerActions';
import filterIcon from '../../assests/img/header/icon-filter-categories.png'


class OffersPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            categories: [],
            confirmOrder: false,
            filteredCards: false,
            orders: {},
            card: {},
            data: this.props.offers,
            fromPoint: null,
            toPoint: null,
            pointsFilteredCard: {},
            pointsOnlyFilter: false,
            categoryName: false
        }
    }
    componentDidMount() {
        if (!this.props.loading && this.props.offers && this.props.offers?.length === 0) {
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/offers'
            })
        }

    }

    componentDidUpdate() {
        if (!this.props.loading && this.props.offers && this.props.offers?.length === 0) {
            this.props.history.push({
                pathname:'/404',
                redirectionURL:'/offers'
            })
        }
    }

    setFromPoint = (val) => {
        this.setState({
            fromPoint: val
        })
    }

    setToPoint = (val) => {
        this.setState({
            toPoint: val
        })
    }



    addActiveClass(elem) {
        let div = document.getElementsByClassName('category')[0]
        div?.classList?.remove('color-skyblue', 'category')
        elem?.classList?.add('category', 'color-skyblue');
    }
    render() {
        // const {currentCategory,brands,establisdhments} = this.state
        const { t } = this.props;
        const { categories } = this.state;

        const breadcrumbs = [
            { name: t('Home'), url: '/', active: true },
            { name: t('Offers'), url: '/offers', active: true },
        ]
        let data = this.props.offers;

        if (this.state.filteredCards) {
            data = this.props.filteredCards
        }

        const desktopView = (
            <div className="d-md-block d-none container-fluid p-0 pb-5">
                <div className="d-flex">
                    <div className="w-100">


                        <Breadcrumbs crumbs={breadcrumbs} />
                        <div className="d-flex p-5 gap-5">
                            <div className='col-2 p-0'>
                                <div className='border-bottom fw-bold '>
                                    <span className='fs-5 headings fw-bold'>{t("Categories")}</span>
                                    <div className='text-muted d-flex flex-column gap-2 py-3'>


                                        {
                                            this.props.category.length > 0 && <Link to="/offers" className='category color-skyblue' onClick={() => {
                                                this.props.sortOfferCards(this.props.category, "Date", "All", true); this.setState({ filteredCards: true, categoryName: false });
                                            }}>{t("All Products")}</Link>
                                        }
                                        {
                                            this.props.category.length > 0 && this.props.category.map(category => <Link
                                            key={category.name}
                                                to={`/offers/${category.name}`}
                                                onClick={(event) => {
                                                    this.props.sortOfferCards(this.props.category, "Date", category.name, true); this.setState({ filteredCards: true, categoryName: category.name });
                                                    this.addActiveClass(event.target);

                                                }

                                                }>{t(category.name)}</Link>)
                                        }
                                    </div>
                                </div>
                                <div className='border-bottom fw-bold py-4'>
                                    <span className='fs-5 headings fw-bold'>{this.props.offers[0]?.currency_name}</span>
                                    <div className={`text-muted d-flex gap-2 py-3 align-items-center justify-content-between cdiv ${this.props.lang === "ar" && "flex-wrap"}`}>
                                        <input
                                            onChange={(e) => { this.setFromPoint(e.target.value) }}
                                            value={this.state.fromPoint}
                                            onWheelCapture={e => { e.target.blur() }}
                                            type="number" min='0' className='p-2 border custinput' style={{ width: '5rem' }} />
                                        {t("to")}
                                        <input
                                            onChange={(e) => { this.setToPoint(e.target.value) }}
                                            value={this.state.toPoint}
                                            min="1"
                                            onWheelCapture={e => { e.target.blur() }}
                                            type="number" className='p-2 border custinput' style={{ width: '5rem' }} />
                                        <button onClick={() => {
                                            this.props.sortOfferCards(this.state.filteredCards ? this.props.filteredCards : this.props.offers, "Date", this.props.categoryName, false, { from: this.state.fromPoint, to: this.state.toPoint }); this.setState({ filteredCards: true });

                                        }} type="button" className={`btn primary-btn fw-bold cusbtn ${this.props.lang === "ar" && "w-100"}`}>{t("Go")}</button>
                                    </div>
                                </div>
                            </div>
                            <div>
                                <div style={{ minWidth: '70vw' }} className='d-flex justify-content-between'>
                                    <div className='pb-4'>
                                        <span className='fs-3 headings fw-bold'>{t("Offers")}</span>
                                        <span className='fs-5 text-muted'> - {t("All")}</span>
                                    </div>
                                    <div >

                                        <Dropdown className='d-inline' >
                                            <DropdownToggle style={{ width: '8rem' }} className='text-light rounded-0 mx-3 py-2 px-0 bg-white no-outline'>
                                                <span className='second-color fw-bold float-left px-3'>{t(this.props.sortBy)}</span>
                                                <span className='arrow-down color-skyblue float-right'></span>
                                            </DropdownToggle>
                                            <DropdownMenu>
                                                <DropdownItem onClick={() => { this.props.sortOfferCards(this.state.filteredCards ? this.props.filteredCards : this.props.offers, "Date", this.props.categoryName, false); this.setState({ filteredCards: true }) }}>{t("Date")}</DropdownItem>
                                                <DropdownItem onClick={() => { this.props.sortOfferCards(this.state.filteredCards ? this.props.filteredCards : this.props.offers, "Value", this.props.categoryName, false); this.setState({ filteredCards: true }) }}>{t("Value")}</DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </div>
                                </div>
                                <div className="d-grid grid-cols-md-4 grid-cols-2 gap-md-3 gap-2 py-3">
                                    {
                                        data && data.map((card, id) => (

                                            <ProductOffersCard card={card} key={id} lang={this.props.lang} />
                                        ))
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )

        const mobileView = (
            <>

                <div className='d-block d-md-none ontainer-fluid px-0 py-3 mb-2'>
                    <div className="px-4">
                        <span className='fs-3 headings fw-bold'>{t("Offers")}</span>
                        <span className='fs-5 text-muted'> - {t("All")}</span>

                        <div className='d-flex flex-row justify-content-end py-2'>

                            <div>
                                <div style={{ maxWidth: "50px", marginRight: "16px", maxHeight: "40px" }} id="dropdownMenuButton" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <img style={{ minWidth: "31px", minHeight: "31px" }} src={filterIcon} alt="categorie" className="img-fluid" />
                                </div>


                                <div className="dropdown-menu " aria-labelledby="dropdownMenuButton">
                                    {
                                        this.props.category && this.props.category.map(category => <Link
                                        key={category.name}
                                            to={`/offers/${category.name}`}
                                            className=" dropdown-item px-4" onClick={() => {
                                                this.props.sortOfferCards(this.props.category, "Date", category.name, true); this.setState({ filteredCards: true });

                                            }
                                            }>{t(category.name)}</Link>)
                                    }
                                </div>

                            </div>

                            <Dropdown style={{ maxWidth: 'fit-content' }} className='d-inline-block'>
                                <DropdownToggle style={{ width: '6.5rem' }} className={`${this.props.lang === 'ar' ? "mr-2" : ""} text-light rounded-0 px-0 pb-1 bg-white no-outline`}>
                                    <span className=' second-color fw-bold float-left px-3'>{t(this.props.sortBy)}</span>
                                    <span className='arrow-down color-skyblue float-right'></span>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => { this.props.sortOfferCards(this.state.filteredCards ? this.props.filteredCards : this.props.offers, "Date", this.props.categoryName, false); this.setState({ filteredCards: true }) }}>{t("Date")}</DropdownItem>
                                    <DropdownItem onClick={() => { this.props.sortOfferCards(this.state.filteredCards ? this.props.filteredCards : this.props.offers, "Value", this.props.categoryName, false); this.setState({ filteredCards: true }) }}>{t("Value")}</DropdownItem>
                                </DropdownMenu>
                            </Dropdown>
                        </div>
                    </div>
                </div>

                <div className="py-md-4 pyt-3 d-grid grid-cols-md-4 grid-cols-2 gap-md-5 gap-2 px-3 d-md-none content-box">

                    {
                        data && data.map((card, id) => (

                            <ProductOffersCard card={card} key={id} lang={this.props.lang} />
                        ))
                    }
                </div>
            </>
        )
        const filterModal = (
            <div className="d-md-none d-block position-fixed bg-light w-100 " style={{ zIndex: '1500', bottom: '4rem' }}>
                {this.state.showNav &&
                    <div className="bg-light overflow-auto py-2" style={{ zIndex: '1500', maxHeight: 'calc(100vh - 6rem)' }}>
                        <div className="py-5  d-flex justify-content-start">

                            <div className="col-2 fs-3 cursor-pointer color-skyblue text-right" onClick={() => { this.toggleNav(false) }} style={{ transform: 'rotate(180deg)' }}>
                                <section >➜</section>
                            </div>
                        </div>
                        <div className='text-muted d-flex flex-column gap-3 border-bottom py-4  px-3 mt-0 pt-0'>
                            <span className='fs-5 headings fw-bold px-3'>{t("Categories")}</span>
                            {
                                categories?.map(category => <Link className="pl-5" to="" key={category.name} onClick={() => {
                                    this.props.sortOfferCards(this.props.category, "Date", category.name, true); this.setState({ filteredCards: true });

                                }

                                }><h6>{t(category.name)}</h6></Link>)
                            }
                        </div>
                        {/* <div className='border-bottom fw-bold  px-3 py-4 '>
                            <span className='fs-5 color-blue fw-bold px-3'>{t("Brands")}</span>
                            <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                                {
                                    brands.map(brand => (
                                        <div className='d-flex align-items-center gap-2 pl-4'>
                                            <input className='d-inline' type="checkbox" />
                                            <span className=''>{t(brand)}</span>
                                        </div>
                                    )) 
                                }
                            </div>
                         </div> */}
                        <div className='border-bottom fw-bold py-4 px-3'>
                            <span className='fs-5 headings fw-bold px-3'>{`${t("Poins")}(SAR)`}</span>
                            <div className='text-muted d-flex gap-2 py-3 align-items-center px-3 '>
                                <input type="number" className='p-2 border' style={{ width: '5rem' }} />
                                {t("to")}
                                <input type="number" className='p-2 border' style={{ width: '5rem' }} />
                                <span className="color-skyblue">{t("Go")}</span>
                            </div>
                        </div>
                        {/* <div className='border-bottom fw-bold px-3 py-4 '>
                            <span className='fs-5 color-blue fw-bold px-3'>Establishment Type</span>
                            <div className='text-muted d-flex flex-column gap-3 py-3 position-relative'>
                                {
                                    establisdhments.map(establisdhment => (
                                        <div className='d-flex align-items-center gap-2 px-3'>
                                            <input className='d-inline' type="checkbox" />
                                            <span className=''>{establisdhment}</span>
                                        </div>
                                    )) 
                                }
                            </div>
                        </div> */}

                        <div className="px-5 py-3">
                            <button type="button" className="btn btn-block btn-primary rounded-0 text-center" onClick={() => { this.toggleNav(false) }}  >{t("Apply Filters")}</button>
                        </div>
                    </div>
                }

            </div>
        )
        return (
            <>
                {(this.props.loading || this.props.loading2) ?
                    <section>
                        <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                            <div className="spinner-border" role="status">
                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>
                    </section> :
                    <div>
                        {desktopView}
                        {mobileView}
                        {filterModal}
                    </div>
                }
            </>
        );
    }
}
    const mapStateToProps = state => {
        return {
            offers: state.offers.offersList,
            category: state.offers.category,
            loading: state.offers.loading,
            sortBy: state.offers.sortBy,
            filteredCards: state.offers.filteredCards,
            offerCard: state.brand.brand,
            countrySelected: state.countries.countryId,
            categoryName: state.offers.categoryName,
            countries: state.countries.countries,
            fetchError: state.offers.error,
            loading2: state.countries.loading,
        }
    }


    const mapDispatchToProps = dispatch => {
        return {

            sortOfferCards: (data, sortBy, categoryName, onlyCategory, onlyPointsFilter) => dispatch(sortOfferCards(data, sortBy, categoryName, onlyCategory, onlyPointsFilter)),
        }
    }

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(OffersPage));
