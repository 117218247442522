export const FETCH_PRODUCTS_LIST_REQUEST = "FETCH_PRODUCTS_LIST_REQUEST";
export const FETCH_PRODUCTS_LIST_SUCCESS = "FETCH_PRODUCTS_LIST_SUCCESS";
export const FETCH_PRODUCTS_LIST_FAILURE = "FETCH_PRODUCTS_LIST_FAILURE";

export const FETCH_PRODUCT_DETAILS_REQUEST = "FETCH_PRODUCT_DETAILS_REQUEST";
export const FETCH_PRODUCT_DETAILS_SUCCESS = "FETCH_PRODUCT_DETAILS_SUCCESS";
export const FETCH_PRODUCT_DETAILS_FAILURE = "FETCH_PRODUCT_DETAILS_FAILURE";

export const FETCH_VARIANTS_REQUEST = "FETCH_VARIANTS_REQUEST";
export const FETCH_VARIANTS_SUCCESS = "FETCH_VARIANTS_SUCCESS";
export const FETCH_VARIANTS_FAILURE = "FETCH_VARIANTS_FAILURE";

export const FETCH_UPDATE_VARIANTS_SUCCESS = "FETCH_UPDATE_VARIANTS_SUCCESS";

export const SORT_MERCHANDISE_CARDS = 'SORT_MERCHANDISE_CARDS';

export const FETCH_MERCHANDISE_TERMS = "FETCH_MERHANDISE_TERMS"