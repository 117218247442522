import React, { Component } from 'react';
import {ReactComponent as AlertSVG} from "../../../assests/img/alert.svg";
import { withTranslation } from 'react-i18next';
import ModalWrapper from '../../shared/Modals/ModalWrapper';
import { Trans } from 'react-i18next';
class TravelConfirmationPopup extends Component {

    showTermsModal = () =>{
        this.props.setModalView();
        this.props.termsModal();
    }
    render() { 
        const {t,setModalView}=this.props
        return ( 
            <ModalWrapper show={this.props.show}>
                <div className="p-4 bg-light mx-auto text-center rounded"
                    style={{ width: '28rem' }}>
                    <div className='d-flex flex-column align-items-center justify-content-center tex-center gap-2'>
                        <AlertSVG />
                        <h4 className='fw-bold active-link'>{t("Caution")}</h4>
                    </div>
                    <div className='d-flex flex-wrap justify-content-center text-center flex-row align-items-center my-3 gap-1 text-muted'>
                    <Trans
                            i18nKey="travelShopCancellationMsg"
                            defaults="Order cannot be <bold>{{boldStatement}}</bold> or <bold>{{refunded}}</bold> once Order is placed"
                            values={{ boldStatement: 'modified/cancelled',refunded:'refunded'}}
                            components={{ bold: <b className='text-dark' /> }}
                        />

                    </div>
                    <div className='d-flex flex-row  justify-content-center text-center align-items-center mb-4 gap-1'>
                        <span className='text-muted'>{t("Please read and accept the")} </span>
                        <span onClick={this.showTermsModal} className='active-link cursor-pointer'> {t("terms and conditions")}</span>
                    </div>
                    <div>
                        <button className="btn rounded-0 btn-border p-2 px-4 mr-4" type="button"
                            onClick={() => setModalView()}>
                            {t("Cancel")}
                        </button>
                        <button className="btn btn-primary rounded mx-3 px-lg-4 my-2 my-md-0" onClick={()=>{setModalView('proceed')}}>
                            {t("Accept & Proceed")}
                        </button>
                    </div>
                </div>
            </ModalWrapper>
         );
    }
}

export default withTranslation()(TravelConfirmationPopup);