import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_SOFTWARE_GAMES_DETAILS, FETCH_SOFTWARE_GAMES_FAILURE, FETCH_SOFTWARE_GAMES_REQUEST, FETCH_SOFTWARE_GAMES_SUCCESS,FETCH_SOFTWARE_GAMES_TERMS, FETCH_SOFTWARE_GAMES_DETAILS_SUCCESS } from "./SoftwareTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';


export const fetchSoftwareGamesCardsRequest = () => {
    return {
        type:FETCH_SOFTWARE_GAMES_REQUEST,
    }
}

export const fetchSoftwareGamesCardsSuccess = (data,featuredProducts,softwareGamesCategory) => {
    return {
        type:FETCH_SOFTWARE_GAMES_SUCCESS,
        payload:{
            data: data,
            category : softwareGamesCategory,
            featuredSoftwareGames: featuredProducts,

        }
    }
}

export const fetchSoftwareGamesCardsFailure = error =>{
    return {
        type:FETCH_SOFTWARE_GAMES_FAILURE,
        payload:error
    }
}

export const fetchSoftwareGames = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_SOFTWARE_GAMES_REQUEST})
        // dispatch(fetchSoftwareGamesCardsRequest);
        axios.post(`${REACT_APP_baseUrl}/software_and_games/get_software_and_games`,
            payload,
            {
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {
                if(response.data?.code === 200){
                   
                    const softwareGamesCategory = [];
                    let featuredProducts = [];
                    let categories = response.data.data.categories;
                     const softwareGamesCards =categories;
                    
                    // categories.forEach(item => {
                    //     softwareGamesCategory.push(item) 
                    //    item.software_and_games.forEach(SGoffer => {
                    //     softwareGamesCards.push(SGoffer)
                    //     SGoffer.featured_flag && featuredProducts.push(SGoffer)
                    //    })
                      
                    // })
                    dispatch(fetchSoftwareGamesCardsSuccess(softwareGamesCards,softwareGamesCategory,featuredProducts)) 
                }
                // if(response.data.data.code === 200) {
                //     const softwareGamesCards = []; 
                //     const softwareGamesCategory =[];        
                //       let  categories  = response.data?.data?.categories;
                //       categories.forEach(e => {
                //           softwareGamesCategory.push(e) 
                //           e.software_and_games.forEach(softwareGames=>{softwareGamesCards.push(softwareGames) softwareGames.featured_flasg})})
                //        dispatch(fetchSoftwareGamesCardsSuccess(softwareGamesCards,softwareGamesCategory))
                //     console.log('sww', categories)              
                // }
                
                else{
                    dispatch(fetchSoftwareGamesCardsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg);
                dispatch(fetchSoftwareGamesCardsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchSoftwareGamesDetails = (payload) => {
    
    return (dispatch)=>{
        dispatch({type:FETCH_SOFTWARE_GAMES_DETAILS})
        axios.post(`${REACT_APP_baseUrl}/software_and_games/software_and_game_details`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {             
                if(response.data?.code === 200){
                    const  details = response.data?.data?.software_and_game;
                    return dispatch({
                        type: FETCH_SOFTWARE_GAMES_DETAILS_SUCCESS,
                        payload:{
                            softwareGameseDetails : details,
                          
                        }
                    })
                   
                }
                else{
                    dispatch(fetchSoftwareGamesCardsFailure(response.data?.message))
                }
                
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg);
                dispatch(fetchSoftwareGamesCardsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchSoftwareGamesTerms = (payload) => {
    return (dispatch)=>{
        axios.post(`${REACT_APP_baseUrl}/software_and_games/software_and_game_terms`,
            payload,
            {
                headers: {
                    'Content-Type': 'application/json',
                    'Cache-Control': 'no-cache, no-store, must-revalidate',
                    'Expires': '0'
                },
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {    
                return dispatch({
                    type: FETCH_SOFTWARE_GAMES_TERMS,
                    payload:{
                        softwareGamesTerms : response.data?.data.terms
                    }
                })
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg);
            })
    }
}
