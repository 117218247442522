import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
import { FETCH_OFFERS_FAILURE, FETCH_OFFERS_REQUEST, FETCH_OFFERS_SUCCESS,SORT_OFFERCARDS,FETCH_OFFER_DETAIL, FETCH_OFFER_DETAIL_SUCCESS, SORT_OFFERCARDS_SUCCESS} from "./offerTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';


export const fetchOffersRequest = () => {
   
    return {
        type:FETCH_OFFERS_REQUEST,
    }
}

export const fetchOffersSuccess = (data,category,featuredProducts) => {
    return {
        type:FETCH_OFFERS_SUCCESS,
        payload:{
            data: data,
            category : category,
            featuredOffers: featuredProducts
        }
    }
}

export const fetchOffersFailure = error =>{
    return {
        type:FETCH_OFFERS_FAILURE,
        payload:error
    }
}



export const fetchOffers = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_OFFERS_REQUEST})
        dispatch(fetchOffersRequest);
        
        axios.post(`${REACT_APP_baseUrl}/offer_campaigns/get_offers_by_currency`,
            payload,
            {
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {

                if(response.data?.code === 200){
                    const offerCards = [];
                    const offerCategory = [];
                    let featuredProducts = [];
                    let categories = response.data.data.categories;
                    
                    categories.forEach(item => {
                       offerCategory.push(item) 
                       item.offers.forEach(offer => {
                        offerCards.push(offer)
                       offer.featured_flag && featuredProducts.push(offer)
                       })
                    })
                    offerCards.sort(function(a, b) {
                        var c = new Date(a.effective_to_date);
                        var d = new Date(b.effective_to_date);
                        return c-d;
                    });
                    dispatch(fetchOffersSuccess(offerCards,offerCategory,featuredProducts))
                }
                else{
                    dispatch(fetchOffersFailure(response.data?.message))
                }
               
            })
            .catch(error => {
                dispatch(fetchOffersFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const sortOfferCards = (data,sortBy,categoryName,onlyCategory,onlyPointsFilter) => (dispatch) => {
    dispatch({type:SORT_OFFERCARDS})
    let sortedCards = undefined;
    let defaultVal = [...data];
    if(onlyCategory){
        
        
        let categorizedCard = [];
        let filteredCards
        if(categoryName === "All"){
            filteredCards = defaultVal
            filteredCards.forEach(item => item.offers.forEach(e => categorizedCard.push(e)))
            categorizedCard.sort((a,b) => new Date(a.effective_to_date)- new Date(b.effective_to_date))
            sortedCards = categorizedCard
        }
        else {
            filteredCards = data.filter(item => item.name === categoryName)
            if(filteredCards.length === 0){sortedCards = categorizedCard}
            else{
                filteredCards.forEach(item => categorizedCard.push(item.offers))
            
                categorizedCard[0].sort(function(a, b) {
                var c = new Date(a.effective_to_date);
                var d = new Date(b.effective_to_date);
                return c-d;
        });
        sortedCards = categorizedCard[0]
        
       }  
        } 

        
    }
    else if(onlyPointsFilter){
       
        let from = onlyPointsFilter.from || 0; 
        let to = onlyPointsFilter.to || 1000000000;
        
       sortedCards = data.filter(item => item.hassad_offer_value >=from && item.hassad_offer_value<=to)
    }
    else if(sortBy === "Value"){
        sortedCards = defaultVal.sort((a,b)=> a.hassad_offer_value - b.hassad_offer_value);

    }
    else{
        sortedCards = defaultVal.sort((a,b)=> new Date(a.effective_to_date) - new Date(b.effective_to_date));
    }
    
    return dispatch({
        type: SORT_OFFERCARDS_SUCCESS,
        payload:{
            cards : sortedCards,
            sortBy:sortBy,
            categoryName: categoryName
        }
    })
}

export const fetchOfferDetails = (payload) => {
    return (dispatch)=>{
        dispatch({type:FETCH_OFFER_DETAIL})
        dispatch(fetchOffersRequest);
        
        axios.post(`${REACT_APP_baseUrl}/offer_campaigns/offer_details`,
            payload,
            {
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {
                if(response.data?.code === 200){
                    const brand = response.data.data
               
                    dispatch({
                        type:FETCH_OFFER_DETAIL_SUCCESS,
                        payload:{
                            offerDetails: brand,
                            offerDetailLoading:false
                        }
                    })
                }
                else{
                    dispatch(fetchOffersFailure(response.data?.message))
                }
              
            })
            .catch(error => {
                dispatch(fetchOffersFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchTermsAndConditions = (payload) => {
    return (dispatch) =>{
        axios.post(`${REACT_APP_baseUrl}/offer_campaigns/offer_terms`,
            payload,
            {
                params:{
                    "secret_key":secretKeyGenerator(payload)
                }
            })
            .then(response  => {
                const terms = response.data.data
               
                dispatch({
                    type:'FETCH_TERMS_AND_CONDITIONS',
                    payload:{
                        termsAndConditions: terms
                    }
                })
            })
            .catch(error => {
                dispatch(fetchOffersFailure("failed"))
            })
    }

}


