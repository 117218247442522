import axios from "axios";
import Loader from "components/shared/Loader";
import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { REACT_APP_baseUrl_V2 } from "utils/app-constants";
import { ReactComponent as Carret } from "../../assests/img/caret-right-fill.svg";
import backButton from "../../assests/img/carret-left-blue.svg";
import { MASTERCARD_BANK_STATEMENT } from "utils/ConstantData";


export class FAQ extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: "",
      activeCollapse: "",
      AllFaqs:[],
      loading:true,
      
    };
  }

  getFAQs = () =>{
    this.setState({loading:true})
    axios.get(`${REACT_APP_baseUrl_V2}/faqs`).then(res =>{
      if(res?.data?.code === 200){
        this.setState({AllFaqs:res?.data?.data?.sections,loading:false},()=>{
          if(res?.data?.data){
            this.handleSection(this.state.AllFaqs?.length > 0 && this.state.AllFaqs[0]?.section_name,this.state.AllFaqs[0]?.faqs !== null && this.state.AllFaqs[0]?.faqs[0]?.title)
          }
        })
      }else{
        this.setState({loading:false})
      }
    }).catch(err =>{
      if(err?.response) this.setState({loading:false})
    })
  }
 
  handleExpandCollaps = (name) => {
    if (this.state.activeCollapse === name) {
      //If collapsiable is already visible and clicked on same then this will hide it
      this.setState({ activeCollapse: "" });
    } else {
      //To show collapsiable
      this.setState({ activeCollapse: name });
    }
  };

  componentDidMount(){
    this.getFAQs()
  }

  componentDidUpdate(prevProps){
    if(prevProps.lang !== this.props.lang) this.getFAQs()
  }

  handleSection = (section,name) =>{
    this.setState({active:section,activeCollapse:""})
  }

  isPhysicalIncluded = () =>{
    return this.state.AllFaqs?.some(val => 
           val?.section_name?.toLowerCase()?.includes("mastercard")
   ) || false;
}

  render() {
    const { t } = this.props;
    const desktopView = (
      <>
        <div className="d-md-block d-none">
          <div className="d-flex flex-column">
            <div>
              <span className="heading-primary">{t("FAQ")}</span>
            </div>
            <div>
              <span className="body-typo">
                {t("Can’t find the answer to your question in the FAQs below?")}
              </span>
              <span>
                <Link
                  to={"/contactus"}
                  className="text-decoration-none p-2  text-center text-primary"
                >
                  {t("Click here to contact us")}
                </Link>
              </span>
            </div>
          </div>

          <div className="d-flex flex-row justify-content-space gap-4 mt-2">
            <div
              className="d-flex flex-column w-35 bg-light p-4 border rounded"
              
            >
              {
                this.state.AllFaqs?.map((val)=>{
                  return(
                    <div
                      className="d-flex flex-row justify-content-between align-items-center border-bottom p-2"
                      onClick={() => this.handleSection(val.section_name,val?.faqs[0]?.title)}
                      style={{ cursor: "pointer" }}
                    >
                      <div
                        className={`d-flex flex-column cursor body-typo ${
                          this.state.active === val.section_name
                            ? "typo-secondary-faq"
                            : " "
                        }`}
                      >
                        {val.section_name}
                      </div>
                      <div className="d-flex flex-column">
                        <Carret
                          width={10}
                          height={10}
                          fill={this.state.active === val.section_name ? "#28A1D4" : " "}
                        />
                      </div>
                    </div>
                  )
                })
              }
            </div>
            <div className="d-flex flex-column w-75 gap-1">
                        <div className="sidebar-nav">
                          <div className="sidebar-nav-menu">
            {
              this.state.AllFaqs?.map((val)=>{
                return(
                  <>
                  {
                    val?.faqs?.map((val2,ind)=>{
                      return(
                        <>
                        {this.state.active === val.section_name && (
                                <div key={`sidebar-nav-menu_${ind}`}
                                  className={`sidebar-nav-menu-item ${
                                    this.state.activeCollapse === val2.title
                                      ? "item-active"
                                      : ""
                                  }`}
                                  onClick={() => this.handleExpandCollaps(val2.title)}
                                  data-id={val2.title}
                                >
                                  <div className="faq-sidebar-nav-menu-item-head">
                                    <span className={`sidebar-nav-menu-item-head-title ${this.state.activeCollapse === val2.title && 'faq-heading'}`} dangerouslySetInnerHTML={{ __html: val2.title }}>
                                      {/* {val2.title} */}
                                    </span>
                                  </div>
                                  <div className="sidebar-nav-menu-item-body">
                                  <div dangerouslySetInnerHTML={{ __html: val2.description }} />
                                    
                                  </div>
                                </div>
                        )}
                        </>
                      )
                    })
                  }
                  </>
                )
              })
            }
            </div>
          </div>
          </div>
          </div>
        </div>
      </>
    );
    const mobileView = (
      <>
        <div className="d-block d-md-none">
          <div style={{ marginBottom: "100px" }}>
          <div className='d-flex flex-row mb-2 d-md-none d-block'>
            {this.props.location.pathname !== "/" && (
              <div
                className=" fs-3 cursor-pointer color-skyblue text-right"
                onClick={() => {
                  this.props?.history?.goBack()
                  // this.props.ShowHeader(true)
                }}
              >
                <section
                  style={{
                    transform: `${this.props.lang === "ar" ? "rotate(180deg)" : ""
                      }`,
                  }}
                >
                  <section
                    style={{
                      marginTop: `${this.props.lang === "ar" ? "8px" : ""}`,
                    }}
                  >
                    <img src={backButton} alt="" style={{marginTop:'5px'}}/> <span className='body-typo' style={{color:'#005E9A'}}>{t("Back")}</span>
                  </section>
                </section>
              </div>
            )}  
            </div>
            <div className="d-flex flex-column">
              <div>
                <span className="heading-primary">{t("FAQ")}</span>
              </div>
              <div>
                <span className="body-typo">
                  {t(
                    "Can’t find the answer to your question in the FAQs below?"
                  )}
                </span>
                <span>
                  <Link
                    to={"/contactus"}
                    className="text-decoration-none p-2  text-center text-primary"
                  >
                    {t("Click here to contact us")}
                  </Link>
                </span>
              </div>
            </div>

            <div className="d-flex flex-column justify-content-space gap-2 mt-2">
              <div className="d-flex flex-row overflow-auto h-auto gap-2">
                {
                  this.state.AllFaqs?.map((val,ind)=>{
                    return(
                      <div key={`handleSection${ind}`}
                        className="d-flex flex-row border rounded justify-content-between align-items-center bg-light"
                        onClick={() => this.handleSection(val.section_name,val?.faqs[0]?.title)}
                        style={{ cursor: "pointer" }}
                      >
                        <div
                          className={`d-flex flex-row  cursor body-typo align-items-center justify-content-center ${
                            this.state.active === val.section_name ? "primary-btn-drc" : " "
                          }`}
                          style={{ width: "140px", padding: "11px" }}
                        >
                          {val.section_name}
                        </div>
                      </div>
                    )
                  })
                }
                </div>
                <div className="d-flex flex-column w-100 gap-1">
                <div className="sidebar-nav">
                <div className="sidebar-nav-menu">

                {
                  this.state.AllFaqs?.map((val)=>{
                    return(
                      <>
                        {
                          val?.faqs?.map((val2,ind)=>{
                            return(
                              <>                  
                                {this.state.active === val.section_name && (
                                      <div key={`sidebar-nav-menu-item${ind}`}
                                        className={`sidebar-nav-menu-item ${
                                          this.state.activeCollapse === val2.title
                                            ? "item-active"
                                            : ""
                                        }`}
                                        onClick={() => this.handleExpandCollaps(val2.title)}
                                        data-id={val2.title}
                                      >
                                        <div className="faq-sidebar-nav-menu-item-head">
                                          <span className={`sidebar-nav-menu-item-head-title ${this.state.activeCollapse === val2.title && 'faq-heading'}`} data-toggle="tooltip" data-placement="top" dangerouslySetInnerHTML={{ __html: val2.title }}>
                                            {/* {val2.title} */}
                                          </span>
                                        </div>
                                        <div className="sidebar-nav-menu-item-body">
                                          <div dangerouslySetInnerHTML={{ __html: val2.description }} />
                                        </div>
                                      </div>

                                )}
                              </>
                            )
                          })
                        }
                      </>
                    )
                  })
                }
                </div>

                </div>
                </div>
            </div>
          </div>
        </div>
      </>
    );
    return (
      <>
      {
        this.state.loading ? <Loader/> : 
        <section className="px-md-5 px-3 py-md-4 py-1 d-flex flex-column justify-content-between ">
          {desktopView}
          {mobileView}
          {
            this.isPhysicalIncluded() &&
        <p className='my-3'>{t(MASTERCARD_BANK_STATEMENT)}</p>
          }
        </section>
      }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      lang: state.countries.lang
  };
};

export default connect(mapStateToProps)(withTranslation()(FAQ));