import React, { Component } from 'react'
import axios from 'axios'
import debounce from '../../utils/debounce';
import { Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import { REACT_APP_baseUrl } from 'utils/app-constants';

let cancelToken
class SearchComponent extends Component {
    constructor(props) {
        super(props);
        this.myRef = React.createRef();
        this.parentRef = React.createRef();
        this.state = {
            countries: undefined,
            fromCountry: undefined,
            inputFocused: false,
            loading: false
        }
        this.searchCountries = this.searchCountries.bind(this);
    }

    componentDidMount() {
        if (this.props.airportsDetails) {
            this.filterAirports(this.props.airportsDetails)
        }
    }
    componentDidUpdate(prevProps) {
        if (typeof prevProps.airportsDetails !== typeof this.props.airportsDetails && !this.state.loading) {
            this.filterAirports(this.props.airportsDetails)
        }
        if (prevProps.value !== this.props.value) {
            this.filterAirports(this.state.countries, this.props.value)
        }

    }
    handleInput = (event) => {
        let inputText = event.target.value
        if (this.state.fromCountry) { this.setState({ fromCountry: undefined }) };
        if (!this.state.loading) { this.setState({ loading: false }) }
        this.searchCountries(inputText)
    }

    filterAirports = (value, place) => {
        if(Array.isArray(value) && value?.length > 0){
            let filteredAirports = value?.filter(e => e.city_code !== this.props.pickedPlace)
        const payload = { countries: filteredAirports }
        if (place) {
            const airport = value?.find(e => e.city_code === place)
            airport && (payload['fromCountry'] = this.props.lang === 'ar' ? airport['airport_name_ar'] : airport['airport_name'])
        }
        this.setState(payload)
        }
    }

    searchCountries = debounce((inputText) => {
        this.searchCountryAPI(inputText)
    }, 250)
    
    searchCountryAPI = (inputText) => {
        if (typeof cancelToken != typeof undefined) {
            cancelToken.cancel("Operation canceled due to new request.")
          }
        
          //Save the cancel token for the current request
        cancelToken = axios.CancelToken.source()
        axios.get(`${REACT_APP_baseUrl}/travel_shops/fetch_service_airports?query=${inputText}`,{ cancelToken: cancelToken.token })
            .then(response => {
                let filteredAirports = response.data.data?.airports;
                if (this.props.pickedPlace) {
                    filteredAirports = response.data.data.airports?.filter(e => e.city_code !== this.props.pickedPlace)
                }
                this.setState({ countries: filteredAirports, loading: false })
            })
    }

    setPlace = (data) => {
        let selectedCountry = this.props.lang === 'ar' ? data?.airport_name_ar : data?.airport_name
        this.setState({ fromCountry: selectedCountry, inputFocused: false })
        this.props.setPlace(this.props.status, data.city_code, data.country_code)
    }

    handleBlur=(event)=>{
        if (!event?.currentTarget?.contains(event?.relatedTarget)) {
            this.setState({ inputFocused: false })
        }
    }

    handleEnterKey=(event, data)=>{
        if(event?.keyCode === 13){
            let selectedCountry = this.props.lang === 'ar' ? data?.airport_name_ar : data?.airport_name
            this.setState({ fromCountry: selectedCountry, inputFocused: false })
            this.props.setPlace(this.props.status, data.city_code, data.country_code)
        }
    }

    ArrowDownPressed = (event) => {
        const node = this.myRef.current
        const inputField = this.parentRef.current

        const keyUpDown = (e) => {
            const active = document?.activeElement;
             if(e?.keyCode === 40 && active?.nextSibling) {
                 active?.nextSibling?.focus();
             }
             if(e?.keyCode === 38 && active?.previousSibling) {
                 active?.previousSibling?.focus();
            }

            if(e?.keyCode === 38){
                if(active?.previousSibling){
                    active?.previousSibling?.focus()
                }
                else{
                    inputField?.focus()
                    node?.removeEventListener('keydown', keyUpDown)
                }
            }
        }

        if((event?.keyCode === 40 || event?.keyCode === 9)  && node!==null){
            event?.preventDefault();
            node?.firstChild?.focus();
            node?.addEventListener('keydown', keyUpDown)}
        }

    render() {
        const { show, showModal, t,lang } = this.props;
        return (
            <div className=''>
                <div className={`w-100 d-md-block d-none border ${this.props.type === 'Flying From' ? 'flyingFrom' : 'flyingTo'}`} onBlur={this.handleBlur}>
                    <div style={{ position: 'relative' }} className='d-block'>
                        {this.state.fromCountry && <span style={{ position: 'absolute', top: '0px' }} className='text-muted mx-1 p-0 travelPlaceHolder'>{t(this.props.type)}</span>}
                        <input onFocus={() => this.setState({ inputFocused: true })} onKeyDown={this.ArrowDownPressed}
                            placeholder={t(this.props.type)} type="text" onChange={this.handleInput} value={this.state.fromCountry} className='border-0 no-outline px-2 py-3 text-truncate searchInputField' ref={this.parentRef}/>

                        {this.props.error ?
                            <h6 className="text-danger py-2 text-center m-0"
                                style={{backgroundColor: "rgb(255, 230, 230)"}}>
                                {this.props.error}
                            </h6>
                            :
                            <React.Fragment/>
                        }
                    </div>
                    {
                        this.state.countries && this.state.inputFocused && <div style={{ width: '300px', minHeight: '50px', position: 'absolute', top: '73%', backgroundColor: 'white', maxHeight: '200px', zIndex: '10000' }} className='border cursor-pointer mt-2 rounded overflow-auto searchSuggestionDiv custom-scrollbar' ref={this.myRef}>
                            {   
                                this.state.countries?.map((data, index, arr) =>
                                    <div key={data.airport_code} onClick={() => this.setPlace(data)} onKeyUp={(e) => {this.handleEnterKey(e, data)}} className="px-1 departureOptions" tabIndex={index}>
                                        <div className="d-flex justify-content-between align-items-center py-1">
                                            <h6 className="m-0 p-0 text-dark fw-bold">{lang === 'ar' ? data?.city_name_ar : data?.city_name}</h6>
                                            <small className='text-muted fw-bold'>{lang === 'ar' ? data?.country_name_ar : data?.country_name}</small>
                                        </div>
                                        <div className="d-flex justify-content-between align-items-center py-1">
                                            <small className='text-muted fw-bold'>{lang === 'ar' ? data?.airport_name_ar : data?.airport_name}</small>
                                            <small className="m-0 p-0 text-muted fw-bold">{data?.airport_code}</small>
                                        </div>
                                        {index !== arr.length - 1 && <hr className='m-0 p-0' />}
                                    </div>
                                )
                            }
                        </div>
                    }
                </div>
                <Modal
                    show={show}
                    backdrop="static"
                    keyboard={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    centered
                    className="d-block d-md-none animate-bottom mobileCitySearch"
                    scrollable={true}
                    style={{ maxHeight: 'calc(100vh - 230px)', overflowY: 'auto', top: '148px' }}
                    animation={false}
                >
                    <Modal.Header >
                        <p className='mb-auto color-blue col-11 text-center fs-md-bolder fs-medium'>{t('Select')} {this.props.status === 'fromPlace' ? t('Departure') : t('Landing')}  {t('Airport')}</p>
                        <div className='justify-content-end fs-medium' onClick={showModal}>x</div>
                    </Modal.Header>
                    <Modal.Title className="text-center">
                        <div className="form-outline" >
                            <input onChange={this.handleInput} style={{ height: 50 }} type="search" id="form1" className="form-control placeop rounded-0 no-gutter" placeholder={t("Search airports")}
                                aria-label="Search" />
                        </div>
                        <div className='row pb-2 border-bottom'>
                            <div className='col-1'>
                            </div>
                        </div>
                    </Modal.Title>
                    <Modal.Body className='m-0 pb-5'>

                        <div className='container-fluid pb-3'>
                            {
                                this.state.countries?.map((e, index, arr) =>
                                    <div key={index} onClick={() => { showModal(); this.setPlace(e) }} className="row flex pb-2 pt-2">
                                        <div className="col-9 pl-0">
                                            <p className='mb-0 ml-2'><span style={{ fontWeight: 'bold' }}>{lang === 'ar' ? e?.city_name_ar : e?.city_name},</span> <span style={{ opacity: 0.5 }}>{lang === 'ar' ? e?.country_name_ar : e?.country_name}</span></p>
                                            <p className='ml-2' style={{ fontSize: 10, opacity: 0.5 }}>{lang === 'ar' ? e?.airport_name_ar : e?.airport_name}</p>
                                        </div>
                                        <div className="col-3">
                                            <div className="text-center p-2 bg-color-green">
                                                {e?.airport_code}
                                            </div>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    </Modal.Body>
                </Modal>
            </div>
        )
    }
}
const mapStateToProps = state => {
    return {
        airportsDetails: state.travel.airportsDetails
    }
}

export default connect(mapStateToProps)(withTranslation()(SearchComponent));

