import { FETCH_FEATUREDCARDS_FAILURE, FETCH_FEATUREDCARDS_REQUEST, FETCH_FEATUREDCARDS_SUCCESS } from "./featureCardsTypes";

const initialState = {
    featureCards:[],
    loading: true,
    error:''
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_FEATUREDCARDS_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_FEATUREDCARDS_SUCCESS:
            return {
                loading:false,
                featureCards:action.payload
            }
        case FETCH_FEATUREDCARDS_FAILURE:
            return {
                loading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;