import axios from "axios"
import secretKeyGenerator from "../../utils/serectKeyGenerator"
// import store from "../store"
import { POST_ORDER_CREATION_FAILURE, POST_ORDER_CREATION_REQUEST, POST_ORDER_CREATION_SUCCESS,FETCH_ORDER_DETAILS_REQUEST,FETCH_ORDER_DETAILS_SUCCESS,FETCH_ORDER_DETAILS_FAILURE, REMOVE_ORDER } from "./orderTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';


export const postOrderCreationRequest = () => {
    return {
        type:POST_ORDER_CREATION_REQUEST,
    }
}

export const postOrderCreationSuccess = data => {
    return {
        type:POST_ORDER_CREATION_SUCCESS,
        payload:data
    }
}

export const postOrderCreationFailure = error =>{
    return {
        type:POST_ORDER_CREATION_FAILURE,
        payload:error
    }
}

export const fetchOrderDetailsRequest = () => {
    return {
        type:FETCH_ORDER_DETAILS_REQUEST,
    }
}

export const fetchOrderDetailsSuccess = data => {
    return {
        type:FETCH_ORDER_DETAILS_SUCCESS,
        payload:data
    }
}

export const fetchOrderDetailsFailure = error =>{
    return {
        type:FETCH_ORDER_DETAILS_FAILURE,
        payload:error
    }
}

export const removeOrder = ()=>{
    return{
        type:REMOVE_ORDER
    }
}

export const fetchOrderDetails = (payload) => {
    return (dispatch)=>{
        dispatch(fetchOrderDetailsRequest());
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/orders/get_order_details`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data?.code === 200){
                    const orderDetails = response.data?.data?.order;
                    dispatch(fetchOrderDetailsSuccess(orderDetails))
                }
                else{
                    dispatch(fetchOrderDetailsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchOrderDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}
export const fetchOrderDetailsForOffers = (payload) => {
    
    return (dispatch)=>{
        dispatch(fetchOrderDetailsRequest());
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/offer_orders/get_order_details`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data?.code === 200){
                    const orderDetails = response.data?.data?.order;
                    dispatch(fetchOrderDetailsSuccess(orderDetails))
                }
                else{
                    dispatch(fetchOrderDetailsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
               
                dispatch(fetchOrderDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchOrderDetailsForTopUp = (payload) => {
   
    return (dispatch)=>{
        dispatch(fetchOrderDetailsRequest());
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/orders/get_topup_order_details`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data?.code === 200){
                    const orderDetails = response.data?.data?.order;
                dispatch(fetchOrderDetailsSuccess(orderDetails))
                }
                else{
                    dispatch(fetchOrderDetailsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
               
                dispatch(fetchOrderDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchOrderDetailsForExperience = (payload) => {
    return (dispatch)=>{
        dispatch(fetchOrderDetailsRequest());
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/experience_orders/get_order_details`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data?.code === 200){
                    const orderDetails = response.data?.data?.order;
                dispatch(fetchOrderDetailsSuccess(orderDetails))
                }
                else{
                    dispatch(fetchOrderDetailsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchOrderDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchOrderDetailsForMerchandise = (payload) => {
    return (dispatch)=>{
        dispatch(fetchOrderDetailsRequest());
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/merchant_product_orders/get_order_details`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data?.code === 200){
                    const orderDetails = response.data?.data?.order;
                dispatch(fetchOrderDetailsSuccess(orderDetails))
                }
                else{
                    dispatch(fetchOrderDetailsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchOrderDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchOrderDetailsForSoftwareGames = (payload) => {
    return (dispatch)=>{
        dispatch(fetchOrderDetailsRequest());
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/orders/get_topup_order_details`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data?.code === 200){
                    const orderDetails = response.data?.data?.order;
                dispatch(fetchOrderDetailsSuccess(orderDetails))
                }
                else{
                    dispatch(fetchOrderDetailsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(fetchOrderDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const fetchOrderDetailsForTravel = (payload) => {
    return (dispatch)=>{
        dispatch(fetchOrderDetailsRequest());
        let token = localStorage.getItem('userToken')
        axios.post(`${REACT_APP_baseUrl}/orders/get_travel_order_details`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data?.code === 200){
                    const orderDetails = response.data?.data?.order;
                dispatch(fetchOrderDetailsSuccess(orderDetails))
                }
                else{
                    dispatch(fetchOrderDetailsFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                dispatch(fetchOrderDetailsFailure("Something went wrong!! Please try again!!"))
            })
    }
}

export const postOrderCreation = (payload) => {
    let token = localStorage.getItem('userToken')
    return (dispatch)=>{
        dispatch(postOrderCreationRequest);
        axios.post(`${REACT_APP_baseUrl}/orders`,
        payload,{
            headers:{
                "Authorization":`Bearer ${token}`
            },
            params:{
                "secret_key":secretKeyGenerator(payload)
            }
        })
            .then(response  => {
                if(response.data.code === 200){
                    const orderDetails = response.data?.data?.order;
                dispatch(postOrderCreationSuccess(orderDetails))
                }
                else{
                    dispatch(postOrderCreationFailure(response.data?.message))
                }
            })
            .catch(error => {
                const errorMsg = error
                console.log(errorMsg)
                dispatch(postOrderCreationFailure(errorMsg))
            })
    }
}
