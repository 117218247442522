import './ProductDetailsImages.css';
import { withTranslation } from 'react-i18next';

export function ProducDetailsImages(props) {
  return <div className="Product-Details-Images">
     {props.images.map(item => (
    <ProductImage item={item} key={item} selected={props.selected} onSelected ={ props.onSelected}    
    >
    </ProductImage>
  ))
  }
  </div>;
}
export function ProductImage(props) {
 const {
  // selected,
  onSelected
 } = props;
 function handleClick(image) {
  onSelected({
    image,
  });
 }
  return (
    <>
   
  <div  className={`Product-Image ${props.selected === props.item ? "selected":" " }`}
         onClick={()=>handleClick(props.item)} >
  <img style={{width:"45px"}} src={props.item} alt={props.item} /> 
 </div>
 </>
  )
}


export default  (withTranslation() (ProducDetailsImages));
