import { FETCH_SOFTWARE_DETAILS_REQUEST, FETCH_SOFTWARE_DETAILS_SUCCESS, FETCH_SOFTWARE_FAILURE, FETCH_SOFTWARE_TERMS_SUCCESS, FETCH_SOFTWARE_REQUEST, FETCH_SOFTWARE_SUCCESS, FETCH_SOFTWARE_DETAILS_FAILURE } from "./SoftwareTypes"



const initialState = {
    softwareCards:[],
    featuredCards:[],
    loading: true,
    error:'',
    softwareDetails:[],
    softwareTerms:[],
    detailsLoading:true,
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_SOFTWARE_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_SOFTWARE_SUCCESS:
            return {
                ...state,
                loading:false,
                softwareCards:action.payload.data,
                featuredCards:action.payload.featuredProducts
            }
        case FETCH_SOFTWARE_FAILURE:
            return {
                loading:false,
                error:action.payload
            }
        case FETCH_SOFTWARE_DETAILS_REQUEST:
            return{
                    ...state,
                    detailsLoading: true,
                    softwareDetails:[],
                    softwareTerms:[],
                    error:''
                }
        case FETCH_SOFTWARE_DETAILS_SUCCESS:
            return{
                    ...state,
                    softwareDetails:action.payload,
                    detailsLoading: false
                }
        case FETCH_SOFTWARE_DETAILS_FAILURE:
            return{
                ...state,
                detailsLoading: false,
                error:action.payload
            }
        case FETCH_SOFTWARE_TERMS_SUCCESS:
            return{
                ...state,
                softwareTerms:action.payload 
            }
        default: return state
    }
   
}

export default reducer;