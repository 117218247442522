import React, { Component } from 'react';
import { connect } from 'react-redux';
import ProductTable from '../ProductTable';
import SearchBar from '../SearchBar';

class OffersSection extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            searchFilter:"",
            dropdownFilter:"ALL",
            isLoaded:true
         }
    }
    headers=["Product","Description","Status","","Quantity"]
    setDropdownFilter = (val) =>{this.setState({dropdownFilter:val})}
    setFilter=(val)=>{this.setState({searchFilter:val})}
    searchFilter=()=>{
       return  this.props.offersOrders && this.props.offersOrders
                .filter(em=>
                    (em.brand_name.match(new RegExp(`${this.state.searchFilter.replace(/[^\w\s]/gi, "")}`,"ig"))
                    &&
                    (this.state.dropdownFilter==="ALL"?
                        true:
                        this.state.dropdownFilter===em.order_status)
                    ))
    }
    render() {
        setTimeout(() => {this.setState({isLoaded: false})}, 100);
        const {dropdownFilter} = this.state;
        return ( 
           <>
            {
               this.state.isLoaded ?
               <section>
                     <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
               </section>
               :
            <div>
                <SearchBar 
                    sectionName="offers"
                    onChange={this.setFilter} 
                    dropdownFilter={dropdownFilter} 
                    setDropdownFilter={this.setDropdownFilter}
                    dropdownItems = {["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]}
                />
                <div>
                    <>
                        <ProductTable type='Offers' headers={this.headers} rows={this.searchFilter()}/>                            
                    </>
                </div>
            </div>
            }
           </>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        offersOrders:state.myWallet.offersOrders
    }
}

export default connect(mapStateToProps)(OffersSection);