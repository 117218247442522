import React, { Component } from 'react';
import { connect } from 'react-redux';
import { fetchPointBalanceFailure } from '../../../Redux/point/pointActions';
import ModalWrapper from './ModalWrapper';
import { withTranslation } from 'react-i18next';

class FlightNotFoundModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            show: true
        }
    }
    setModalView = () => {
        this.setState({
            show: false
        })
    }
    render() {
        const { t } = this.props
        return (
            <ModalWrapper show={this.props.show}>
                <div
                    className="p-4 bg-light mx-auto text-center py-md-5 py-4"
                    style={{ width: '40rem' }}>
                    <br />
                    <h4>{t("No Record Found")}</h4>
                    <span>{t("No results found for this particular search, Please try again!")}</span>
                    <div>
                        <button
                            className="btn  border-color-blue mt-3 mx-2"
                            onClick={() => { this.props.handleEditFlightDetail() }}>
                            <span className="fw-bold color-blue">{t("Edit search")}</span>
                        </button>
                    </div>
                </div>
            </ModalWrapper>
        );
    }
}

export default withTranslation()(FlightNotFoundModal)