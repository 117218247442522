import { ADD_CONTACT_DETAIL, UPDATE_PASSENGER_COUNT, ADD_PASSENGER_DETAIL, FETCH_AIRPORTS, SET_SEARCH_RESULTS, SET_SELECTED_FLIGHT_DETAILS, SET_FILTER_DATA, SET_SEARCH_ORDER, SET_SEARCH_QUERY_DATA, REMOVE_DATA_AFTER_PAYMENT, SET_BRAND_ID, MOBILE_DEVICE, CLEAR_SEARCH_RESULTS } from "./TravelTypes"
import CryptoJS from "crypto-js";
import cookie from 'react-cookies';

const keyExtraction = (propName) => {
    if (propName.includes('passengerDetails')) { return process.env.REACT_APP_PASSENGERKEY }
    else if (propName === 'contactDetails') { return process.env.REACT_APP_CONTACTKEY }
    else { return undefined }
}

const encryptValueToCookie = (propName, value) => {
    let key = keyExtraction(propName) || '';
    const encrypted = CryptoJS.AES.encrypt(JSON.stringify(value), key).toString();
    cookie.save(propName, encrypted, {
        path: '/',
        expires: new Date(new Date().getTime() + 30 * 60 * 1000),
        secure: true
    });
}

const decryptValueFromCookie = (propName) => {
    let value = cookie.load(propName);
    let key = keyExtraction(propName) || 'invalid key';
    if (value && key !== 'invalid key') {
        let decrypted = CryptoJS.AES.decrypt(value, key).toString(CryptoJS.enc.Utf8);
        if (decrypted) {
            return JSON.parse(decrypted);
        }
        else { return {} }
    }
    else { return null }
}


const initialState = {
    passengerCount: JSON.parse(localStorage.getItem('passengerCount')) || 1,
    passengerCountDetails: JSON.parse(localStorage.getItem('passengerCountDetails')) || { "adults": 1, "child": 0, "infant": 0 },
    passengerDetails: { ...decryptValueFromCookie('passengerDetails'), ...decryptValueFromCookie('passengerDetails2') } || {},
    contactDetails: decryptValueFromCookie('contactDetails') || {},
    airportsDetails: undefined,
    filterData: {},
    searchOrder: 'best',
    searchResults: {},
    selectedFlightDetail: {},
    filters:{},
    searchQueryData: {},
    brandID:undefined,
    mobileDevice:false
}

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case UPDATE_PASSENGER_COUNT:
            localStorage.setItem('passengerCount', JSON.stringify(action.payload.passengerCount));
            localStorage.setItem('passengerCountDetails', JSON.stringify(action.payload.passengerCountDetails));
            return {
                ...state,
                passengerCount: action.payload.passengerCount,
                passengerCountDetails: action.payload.passengerCountDetails
            }
        case ADD_PASSENGER_DETAIL:
            let newPassengerDetails = { ...state.passengerDetails };
            newPassengerDetails[action.payload.uid - 1] = action.payload.passengerDetails;
            if (action.payload.uid > 5) {
                let splitObj = {}
                Object.keys(newPassengerDetails).forEach((data, index) => {
                    if (index > 6) { splitObj[index] = newPassengerDetails[index] }
                })
                encryptValueToCookie('passengerDetails2', splitObj);
            }
            else {
                let obj = {};
                Object.keys(newPassengerDetails).forEach((data, index) => {
                    obj[index] = newPassengerDetails[index]
                })
                encryptValueToCookie('passengerDetails', newPassengerDetails);
            }
            return {
                ...state,
                passengerDetails: newPassengerDetails
            }
        case ADD_CONTACT_DETAIL:
            encryptValueToCookie('contactDetails', action.payload.contactDetails);
            return {
                ...state,
                contactDetails: action.payload.contactDetails
            }
        case FETCH_AIRPORTS:
            return {
                ...state,
                airportsDetails: action.payload
            }
        case SET_SEARCH_ORDER:
            return {
                ...state,
                searchOrder: action.payload
            }
        case SET_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: { ...state.searchResults, ...action.payload }
            }
        case MOBILE_DEVICE:
            return{
                ...state,
                mobileDevice:true
            }
        case CLEAR_SEARCH_RESULTS:
            return {
                ...state,
                searchResults: {}
        }
        case SET_SEARCH_QUERY_DATA:
            return {
                ...state,
                searchQueryData: action.payload
            }
        case SET_SELECTED_FLIGHT_DETAILS:
            return {
                ...state,
                selectedFlightDetail: action.payload
            }
        case SET_FILTER_DATA:
            return {
                ...state,
                filters: action.payload
            }
        case REMOVE_DATA_AFTER_PAYMENT:
            return{
                ...state,
                passengerCount: 1,
                passengerCountDetails: { "adults": 1, "child": 0, "infant": 0 },
                passengerDetails: {},
                contactDetails: {},
                airportsDetails: undefined,
                filterData: {},
                searchOrder: 'best',
                searchResults: {},
                selectedFlightDetail: {},
                searchQueryData: {}
            }
        case SET_BRAND_ID:
            return{
                ...state,
                brandID: action.payload
            }
        default:
            return state
    }
}


export default reducer