import React, { Component } from "react";
import { Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

class SelectPassengerModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      passengerType:"economy"
    };
  }
  render() {
   
   const bookingClass =[ "Economy" , "Business", "first"]
    const {
      show,
      showModal,
      passengerCount, 
      passengerCountFunction,
      passengerTypeChange,
      passengerType,
      passengerTypeCheck,changePassengerClass,passengerClass,t
    } = this.props;
    
    return (
      <Modal
        closeButton
        show={show}
        backdrop="static"
        onHide={showModal}
        keyboard={true}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="d-block d-md-none"
        scrollable={true}
        style={{'max-height': '78vh', 'overflow-y': 'auto' ,"top":"14%"}}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Title className="text-center">
          {t("Passenger & Cabin Class")}
        </Modal.Title>
        <Modal.Body className='m-0'>
         <div className=' px-2 py-2' style={{backgroundColor:"#f0f0f0"}}>
         <h5 className='color-blue'>{t("Passengers")}</h5>
         </div>
          <div>
            <p>{t("Adults (Age 12+)")}</p>
            <div className=" d-flex justify-content-between">
              <h4> {passengerCount.adults}</h4>{" "}
              <div className="text-right ">
                <button
                  onClick={()=> passengerCountFunction('adults','sub')}
                  className="p-2 px-3 border mx-2"
                >
                  -
                </button>{" "}
                <button
                  onClick={()=>passengerCountFunction('adults','add')}
                  className="p-2 px-3 increment-decrement-btn mx-2"
                >
                  +
                </button>
              </div>
            </div>
          </div>
          <p>{t("Children (Age 2 - 12)")}</p>
          <div className=" d-flex justify-content-between mt-3">
            <h4> {passengerCount.child}</h4>
            <div className="text-right ">
              <button
                onClick={()=>passengerCountFunction('child','sub')}
                className="p-2 px-3 border mx-2"
              >
                -
              </button>{" "}
              <button
                onClick={()=>passengerCountFunction('child','add')}
                className="p-2 px-3 increment-decrement-btn mx-2"
              >
                +
              </button>
            </div>
          </div>
          <p>{t("Infants (Age under 2 , on lap)")}</p>
          <div className=" d-flex justify-content-between mt-3">
            <h4> {passengerCount.infant}</h4>
            <div className="text-right ">
              <button
                onClick={()=>passengerCountFunction('infant','sub')}
                className="p-2 px-3 border mx-2"
              >
                -
              </button>{" "}
              <button
                onClick={()=>passengerCountFunction('infant','add')}
                className="p-2 px-3 increment-decrement-btn mx-2"
              >
                +
              </button>
            </div>
          </div>
          <div className=' px-2 py-2 mt-3' style={{backgroundColor:"#f0f0f0"}}>
         <h5 className='color-blue'>{t("Class")}</h5>
         </div>
         <form>
         {["Economy","Business","First"].map(item => (
            <div className='d-flex my-3 justify-content-between'>
            <span>{t(item)}</span>
            <input defaultChecked={item === passengerClass}  onChange={() => changePassengerClass(item)} name='tripTypeCheckbox' type="radio" className="mx-2 passengeModalRadio"/>                  
       </div>
         ))}
         </form>
         
          <div className="text-center mt-5 pb-5">
            <button className="btn primary-btn  p-3 fs-4" onClick={showModal
            }>
              {t("Continue")}
            </button>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
}

export default withTranslation()(SelectPassengerModal)
