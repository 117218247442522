import React, { Component } from "react";
// import FlightStop from "../TravelShop/Modal/FlightStopModal";
// import FlightSort from "../TravelShop/Modal/FlightSortModal";
import FilterModal from "../TravelShop/Modal/FilterModal";
import SortIcon from '../../assests/img/TravelShop/sort-icon.svg'
import Emirates from '../../assests/img/TravelShop/emirates.svg'
import British from '../../assests/img/TravelShop/british.svg'
import AirportAirlines from "../TravelShop/Modal/AirportAirlines";
import TravelShopFlightsBox from "./TravelShopFlightsBox";
import TravelDetailTopBar from "./TravelDetailTopBar";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { setSearchOrder, setSelectedFlightDetails } from "../../Redux/travelShop/TravelAction";
import { withTranslation } from "react-i18next";

class SearchFlights extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sort: false,
      flexible: false,
      stops: false,
      times: false,
      airports: false,
      stopModal: false,
      sortModal: false,
      showAirport: false,
      airlines: false,
      showAirlines: false,
      loading: false
    };
  }
  filterHandler(name) {
    this.setState((prev) => ({ [name]: !prev[name] }));
  }
  closeModal = () => {
    this.setState({
      stopModal: false,
      sortModal: false,
      showAirport: false,
      showAirlines: false,
    });
  };
  handleSort = (name) => {
    this.setState((prev) => ({ [name]: true }));
  };
  clearFilter = (name) => {
    this.setState((prev) => ({ [name]: false }));
  };
  render() {
    const customizeNodal = {
      opacity: ".5 !important",
    };
    const flightStop = [["Any", "11,429 its"], ["Direct", "11,429 its"], ["1 Stop", "11,429 its"], ["2 Stop", "15,283 its"]];
    const flightSort = [["Cheapest", "11,429 its"], ["Best", "11,429 its"], ["Quickest", "11,429 its"], ["Earliest", "11,429 its"]];
    const Airports = [
      "DOH Doha",
      "DXB Dubai",
      "ISB Islamabad",
      "IST Istanbul",
      "KHI Karachi",
      "JED Jeddah",
    ];
    const Airlines = [
      "AIr Arabia Abi Dhabi",
      "Egypt Air",
      "Emirates",
      "Etihed Airways",
      "FlyDubai",
      "Gulf Air",
      "Kuwait Airways",
      "Pakisthan Inernational",
    ];
    const FlightsList = [
      {
        flightImage: Emirates,
        returnFlightImage: British,
        flightName: 'Emirates',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'DXB'],
        landing: ['4:30 pm', 'LHR'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490',
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
      {
        flightImage: British,
        returnFlightImage: British,
        best: 'Best',
        fees: 'No change fees',
        stops: '2 Stops',
        flightName: 'British Airways',
        returnFlightName: 'British Airways',
        takeOff: ['12:10 pm', 'LHR'],
        landing: ['4:30 pm', 'DXB'],
        returnFlightTakeOff: ['12:10 pm', 'LHR'],
        returnFlightLanding: ['4:30 pm', 'DXB'],
        totalTime: '7h 10m',
        returnFlightTotalTime: '7h 10m',
        points: '18,490'
      },
    ];

    const { t } = this.props
    return (   
      <div className=" d-md-none d-block">
        <FilterModal
          type={"stops"}
          filterData={flightStop}
          clear={this.clearFilter}
          sort={this.handleSort}
          show={this.state.stopModal}
          style={customizeNodal}
          showModal={this.closeModal}
        />
        <FilterModal
          type={"sort"}
          filterData={flightSort}
          clear={this.clearFilter}
          sort={this.handleSort}
          show={this.state.sortModal}
          style={customizeNodal}
          showModal={this.closeModal}
        />
        <AirportAirlines
          title={"Airports"}
          show={this.state.showAirport}
          showModal={this.closeModal}
          shopover={Airports}
          type={"airports"}
          clear={this.clearFilter}
          sort={this.handleSort}
        />
        <AirportAirlines
          title={"Airlines"}
          show={this.state.showAirlines}
          showModal={this.closeModal}
          shopover={Airlines}
          type={"airlines"}
          clear={this.clearFilter}
          sort={this.handleSort}
        />
        <TravelDetailTopBar takeOff="LHR" landing="DXB" desc="13 Oct - 10 Nov | 1 pax | Economy" handleSearchForm={this.props.handleSearchForm}/>
        <div className="d-flex border-bottom" style={{ overflowX: "auto" }}>
          <button
            onClick={() => {
              // this.filterHandler("Sort");
              this.setState({ sortModal: true });
            }}
            className={`${this.state.sort === true ? " filterButton-clicked" : ""
              } btn filterButton mx-2 my-3 px-3 py-1 `}
          >
            <img loading='lazy' decoding='async' src={SortIcon} className="mr-2" alt="" /> Sort
          </button>
          <button
            onClick={() => this.filterHandler("flexible")}
            className={`${this.state.flexible === true ? " filterButton-clicked" : ""
              } btn filterButton mx-2 my-3 px-3 py-1 `}
          >
            {t("Flexible")}
          </button>
          <button
            onClick={() => {
              // this.filterHandler("stops");
              this.setState({ stopModal: true });
            }}
            className={`${this.state.stops === true ? " filterButton-clicked" : ""
              } btn filterButton mx-2 my-3 px-3 py-1 `}
          >
            {t("Stops")}
          </button>
          <button
            onClick={() => this.filterHandler("times")}
            className={`${this.state.times === true ? " filterButton-clicked" : ""
              } btn filterButton mx-2 my-3 px-3 py-1 `}
          >
            {t("Times")}
          </button>
          <button
            // onClick={() => this.filterHandler("airports")}

            onClick={() => {
              // this.filterHandler("Sort");
              this.setState({ showAirport: true });
            }}
            className={`${this.state.airports === true ? " filterButton-clicked" : ""
              } btn filterButton mx-2 my-3 px-3 py-1 `}
          >
            {t("Airports")}
          </button>
          <button
            // onClick={() => this.filterHandler("airports")}

            onClick={() => {
              // this.filterHandler("Sort");
              this.setState({ showAirlines: true });
            }}
            className={`${this.state.airlines === true ? " filterButton-clicked" : ""
              } btn filterButton mx-2 my-3 px-3 py-1 `}
          >
            {t("Airlines")}
          </button>
        </div>
        <div className="flights-result-data px-3 pt-4">
          <h5 className="color-blue">{t("Select result flight to")} {this.props.searchResults?.segments[0]?.destination}</h5>
          <p className="mb-0">{t(`Average ${this.props.searchResults?.segments?.length > 1 ? 'round' : 'one way'} price trip per passenger`)}</p>
          <span>{t("Showing")} {this.props.searchResults?.search.length} {t("flights")} | {t("The price includes taxes and fees")}</span>
        </div>
        {/* <div className="px-3" style={{ paddingBottom: 80 }}>
          {
            FlightsList && FlightsList.length > 0 && FlightsList.map((data, i) => (
              <TravelShopFlightsBox
                className={data.className}
                key={i}
                flightImage={data.flightImage}
                returnFlightImage={data.returnFlightImage}
                best={data.best}
                fees={data.fees}
                stops={data.stops}
                flightName={data.flightName}
                returnFlightName={data.returnFlightName}
                takeOff={data?.takeOff}
                landing={data?.landing}
                returnFlightTakeOff={data?.returnFlightTakeOff}
                returnFlightLanding={data?.returnFlightLanding}
                totalTime={data.totalTime}
                returnFlightTotalTime={data.returnFlightTotalTime}
                points={data.points}
              />
            ))
          }
        </div> */}
        {console.log(`this.props.searchResults?.search`, this.props.searchResults)}
        <div className="px-md-3" style={{ paddingBottom: 80 }}>
          {
            this.props.searchResults?.search.map((data, i) => (
              <TravelShopFlightsBox
                data={data}
                segments={this.props.searchResults.segments}
                className={data.className}
                key={i}
                flightImage={data.flightImage}
                returnFlightImage={data.returnFlightImage}
                best={data.best}
                fees={data.fees}
                stops={data.stops}
                flightName={data.flightName}
                returnFlightName={data.returnFlightName}
                takeOff={data?.takeOff}
                landing={data?.landing}
                returnFlightTakeOff={data?.returnFlightTakeOff}
                returnFlightLanding={data?.returnFlightLanding}
                totalTime={data.totalTime}
                returnFlightTotalTime={data.returnFlightTotalTime}
                points={data.points}
                handleViewDetail={this.props.handleViewDetail}
              />
            ))
          }
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchResults: state.travel.searchResults,
    searchOrder: state.travel.searchOrder,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    setSelectedFlightDetails: (data) => dispatch(setSelectedFlightDetails(data)),
    setSearchOrder: (data) => dispatch(setSearchOrder(data)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTranslation()(withRouter(SearchFlights)));
