import React, { Component } from "react";
import { ReactComponent as PlaneDown } from "../../assests/img/TravelShop/plane-down.svg";
import { ReactComponent as Seat } from "../../assests/img/TravelShop/seat.svg";
import { ReactComponent as Baggage } from "../../assests/img/TravelShop/baggage.svg";
import { ReactComponent as PlaneUp } from "../../assests/img/TravelShop/plane-up.svg";
import moment from "moment";




class ConfirmationStepFlight extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { selectedFlightDetail, searchResults, flightDetails } = this.props

    return (
      <>
        <div className="flight-step mt-3">
          <div className="step-flight-wrap">
            {flightDetails &&
              flightDetails?.length > 0 &&
              flightDetails.map((data, index) => {
                return (
                  <div className="step-flight" key={index}>
                    <img loading='lazy' decoding='async' width="100" className="mb-2" src={data?.airline_image} alt="" />
                    <h5 className="text-gray-100 mb-1">{data?.airline_name}</h5>
                    <span className="text-gray">Flight {data?.airline_code} {data?.flight_number}</span>
                  </div>
                );
              })}
          </div>
          <div className="step-flight-info">
            {flightDetails &&
              flightDetails?.length > 0 &&
              flightDetails.map((data, index) => {
                return (
                  <div className="step-flight-inner d-flex justify-content-between align-items-center" key={index}>
                    <div className="step-flights">
                      <div className="flights-up">
                        <h4>{data?.origin?.city}</h4>
                        <p>{moment(data?.departure_time).format('HH:mm | ddd, DD MMM')}</p>
                        <span>{moment(data?.duration, 'hh:mm:ss').format('H')} hr {moment(data?.duration, 'hh:mm:ss').format('mm')} mins</span>
                      </div>
                      <div className="flights-down">
                        <h4>{data?.destination?.city}</h4>
                        <p>{moment(data?.arrival_time).format('HH:mm | ddd, DD MMM')}</p>
                      </div>
                    </div>
                    <div className="flight-options w-25">
                      <ul className="p-0 m-0">
                        <li>
                          <Seat />
                          <span>{data?.cabin_class}</span>
                        </li>
                        <li>
                          <Baggage />
                          <span>{data?.included_baggage}</span>
                        </li>
                      </ul>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </>
    );
  }
}
export default ConfirmationStepFlight;
