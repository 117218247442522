import { CONFIRM_ACC_REQ, CONFIRM_ACC_SUCCESS, CONFIRM_ACC_FAILURE } from "./confirmAccTypes";

const initState = {
    loading:false,
    status:"",
    err:""
}

const reducer = (state = initState, action) =>{
    switch (action.type) {
        case CONFIRM_ACC_REQ:
            return{
                ...state,
                loading:true
            }
        case CONFIRM_ACC_SUCCESS:
            return{
                ...state,
                loading:false,
                status:action.payload
            }
        case CONFIRM_ACC_FAILURE:
            return{
                ...state,
                loading:false,
                err:action.payload
            }
        default:
            return state
    }
}

export default reducer;