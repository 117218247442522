import { FETCH_BRAND_FAILURE, FETCH_BRAND_REQUEST, FETCH_BRAND_SUCCESS,
    GET_BRAND_FAILURE, GET_BRAND_REQUEST, GET_BRAND_SUCCESS } from "./brandTypes"

const initialState = {
    brand:{},
    loading:true,
    error:'',
    errorMsg:'',
    brandData:undefined,
    prodNotFound:false
}

const reducer = (state = initialState,action) => {
    switch(action.type){

        case FETCH_BRAND_REQUEST:
            return {
                ...state,
                loading:true,
                prodNotFound:false,
                brand:{},
                errorMsg:'',
            }
        case FETCH_BRAND_SUCCESS:
            return {
                ...state,
                loading:false,
                brand:action.payload,
                prodNotFound:false,
                errorMsg:'',
            }
        case FETCH_BRAND_FAILURE:
            return {
                ...state,
                loading:false,
                prodNotFound:true,
                errorMsg:action.payload
            }
        case GET_BRAND_REQUEST:
                return {
                    ...state,
                    loading:true,
                    brandData:undefined,
                    error:'',
                }
        case GET_BRAND_SUCCESS:
            return{
                ...state,
                loading:false,
                brandData:action.payload
            }
        case GET_BRAND_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;