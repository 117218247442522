import React from 'react'
import {Prompt, withRouter} from 'react-router-dom'
import Backpopup from '../components/TravelShop/Backpopup'
export class RouteLeavingGuard extends React.Component {
 state = {
   modalVisible: false,
   lastLocation: null,
   confirmedNavigation: false,
 }
 showModal = (location) => this.setState({
   modalVisible: true,
   lastLocation: location,
 })
 closeModal = (callback) => this.setState({
   modalVisible: false
 }, callback)

 cancelModal = (callback) =>this.setState({
   modalVisible:false,
   confirmedNavigation:false
 })
 handleBlockedNavigation = (nextLocation) => {
  //  Should return false or true for browser prompt box functionality
   const {confirmedNavigation} = this.state
   const {shouldBlockNavigation} = this.props
   if (!confirmedNavigation && shouldBlockNavigation(nextLocation)){
       this.showModal(nextLocation)
       return false
   }
   else{
    return true
   }
 }
 handleConfirmNavigationClick = () => this.closeModal(() => {
   const {navigate} = this.props
   const {lastLocation} = this.state
   if (lastLocation) {
      this.setState({
         confirmedNavigation: true
      }, () => {
         // Navigate to the previous blocked location with your navigate function     
         navigate(lastLocation.pathname)
         // Set Timeout for Nested Routes 
         setTimeout(()=>{this.setState({confirmedNavigation:false})},100)
      })
   }
 })

//  To restrict on reload and closing the tab
   componentDidMount(){
        window.addEventListener('beforeunload', this.handleLeavePage);
    }

    componentWillUnmount() {
        window.removeEventListener('beforeunload', this.handleLeavePage);
    }
    handleLeavePage(e) {
        const confirmationMessage = '';
        e.returnValue = this.handleBlockedNavigation;
        return e.returnValue;
      }
 render() {
   const {when,history} = this.props
   const {modalVisible, lastLocation} = this.state;
   return (
     <>
        <Prompt
           when={when}
           message={this.handleBlockedNavigation}/>
           {modalVisible && <Backpopup cancelModal={this.cancelModal} confirm={this.handleConfirmNavigationClick}/>}
     </>
   )
 }
}
export default withRouter(RouteLeavingGuard)