import './Variants.css';
import { useTranslation } from 'react-i18next';
export function Variants(props) {
  const {
    title,
   onSelected,
   items
  } = props;
const { t } = useTranslation();
  return <div className="pt-variants-section">
    <div className="pt-variants-title">
      <span className="pt-title fw-bold">{t(title)}:</span>
    <span className="pt-value text-dark fw-bold">{  items.selectedTitle }</span>
    </div>
    <div className="pt-variants">
    {items.items.map(variant => (
      <Variant key={variant} keyVariant={title} {...variant} onSelected ={ onSelected}>
      </Variant>
    ))
    }
      </div>
  </div>


}
export function Variant(props) {
  const {
    title,
    color,
    selected,
    onSelected,
    disableOrHide,
    keyVariant
  } = props;
  function handleClick() {
    onSelected({
      key: keyVariant,
      title,
      color,
      selected : true,
      disableOrHide

    });
}
  return <div  className={`pt-variant mr-3 ${selected  ? "selected" : ""} ${disableOrHide  ? "disable-or-hide" : ""}` } onClick={handleClick} >
    {color&& <span style={{backgroundColor: color}} className="pt-variant-color mr-2"></span>}
    <span className="pt-variant-title">{title}</span>
  </div>
}

export default Variants;
