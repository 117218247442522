import {Gift_Submit_Details} from './giftFlowTypes.js'

const initialState = {message: '', to:'',from:'',email:'',mobile:'', url:'', giftCountryCode:'', GiftCardSelect: '', GiftSliderIndex: '', GiftFormat: '',GiftParticularCardSelect:'',GiftParticularSliderIndex:''}

const giftFlowChanger = (state = initialState,action) => {
    switch(action.type) {
        case Gift_Submit_Details:{
            return  action.payload
        }
        default: return state
    }
}

export default giftFlowChanger

