export const ADD_PASSENGER_DETAIL = 'ADD_PASSENGER_DETAIL'
export const ADD_CONTACT_DETAIL = 'ADD_CONTACT_DETAIL'
export const UPDATE_PASSENGER_COUNT = "UPDATE_PASSENGER_COUNT";
export const FETCH_AIRPORTS = 'FETCH_AIRPORTS'
export const SET_SEARCH_ORDER = 'SET_SEARCH_ORDER'
export const SET_SEARCH_RESULTS = 'SET_SEARCH_RESULTS'
export const SET_SEARCH_QUERY_DATA = 'SET_SEARCH_QUERY_DATA'
export const SET_SELECTED_FLIGHT_DETAILS = 'SET_SELECTED_FLIGHT_DETAILS'
export const SET_FILTER_DATA = 'SET_FILTER_DATA'
export const REMOVE_DATA_AFTER_PAYMENT = 'REMOVE_DATA_AFTER_PAYMENT'
export const SET_BRAND_ID = 'SET_BRAND_ID'
export const MOBILE_DEVICE = "MOBILE_DEVICE"
export const CLEAR_SEARCH_RESULTS = "CLEAR_SEARCH_RESULTS"
