import { FETCH_GIFTCARDS_FAILURE, FETCH_GIFTCARDS_REQUEST, FETCH_GIFTCARDS_SUCCESS} from "./giftCardTypes"

const initialState = {
    giftCards:[],
    loading: true,
    error:'',
    filteredCards : [],
    letter:'Default'
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_GIFTCARDS_REQUEST:
            return {
                ...state,
                loading:true,
                error:''
            }
        case FETCH_GIFTCARDS_SUCCESS:
            return {
                ...state,
                loading:false,
                giftCards:action.payload,
                error:''
            }
        case FETCH_GIFTCARDS_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
           
        default: return state
    }
}

export default reducer;
