import React, { Component } from 'react';

export class ShopBrand extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         
      }
    }
    
  render() {
    const {card,device}= this.props
    return <div className={`cursor-pointer ${device === "mobile" ? "shop-cat" : ""}`}>
                <img className={`img-fluid w-100 `} src={card && card }    alt="img" />
            </div>

  }
}

export default ShopBrand;
