import axios from "axios"
import { remove } from "react-cookies"
import { REACT_APP_baseUrl } from "utils/app-constants"
import { ADD_CONTACT_DETAIL, UPDATE_PASSENGER_COUNT, ADD_PASSENGER_DETAIL, FETCH_AIRPORTS, SET_SEARCH_RESULTS, SET_SELECTED_FLIGHT_DETAILS, SET_SEARCH_ORDER, SET_SEARCH_QUERY_DATA, REMOVE_DATA_AFTER_PAYMENT, SET_FILTER_DATA, SET_BRAND_ID, MOBILE_DEVICE, CLEAR_SEARCH_RESULTS} from "./TravelTypes"

export const MobileDevice = () =>{
    return(dispatch) =>{
        dispatch({
            type : MOBILE_DEVICE
        })
    }
}

export const ClearSearchResults = () =>{
    return(dispatch) =>{
        dispatch({
            type : CLEAR_SEARCH_RESULTS
        })
    }
}

export const addPassengersDetails = (passengerDetails, uid) => {
    return (dispatch) => {
        dispatch({
            type: ADD_PASSENGER_DETAIL,
            payload: {
                passengerDetails: passengerDetails,
                uid: uid
            }
        })
    }
}

export const addContactDetails = (contactDetails) => {
    return (dispatch) => {
        dispatch({
            type: ADD_CONTACT_DETAIL,
            payload: {
                contactDetails: contactDetails
            }
        })
    }
}

export const updatePassengerCount = (passengerCountDetails) => {
    let passengerCount = Object.values(passengerCountDetails).reduce((a, b) => a + b);
    return (dispatch) => {
        dispatch({
            type: UPDATE_PASSENGER_COUNT,
            payload: {
                passengerCountDetails: passengerCountDetails,
                passengerCount: passengerCount
            }
        })
    }
}


export const fetchAirport = () => {
    return (dispatch) => {
        axios.get(`${REACT_APP_baseUrl}/travel_shops/fetch_service_airports`)
            .then(response => {
                dispatch({
                    type: FETCH_AIRPORTS,
                    payload: response.data.data?.airports
                })
            })
    }
}


export const setSearchResults = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_SEARCH_RESULTS,
            payload
        })
    }
}


export const setSearchQueryData = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_SEARCH_QUERY_DATA,
            payload
        })
    }
}

export const setSearchOrder = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_SEARCH_ORDER,
            payload
        })
    }
}
export const setSelectedFlightDetails = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_SELECTED_FLIGHT_DETAILS,
            payload
        })
    }
}
export const setFilterData = (payload) => {
    return (dispatch) => {
        dispatch({
            type: SET_FILTER_DATA,
            payload
        })
    }
}

export const setBrandID = (brandID) => {
    return (dispatch) => {
        dispatch({
            type : SET_BRAND_ID,
            payload:brandID
        })
    }
}

export const removeDataAfterPayment = () => {
    ['contactDetails','passengerDetails'].forEach(e => remove(e,{path:'/'}));
    ['passengerCount','passengerCountDetails'].forEach(e => localStorage.removeItem(e))
    return (dispatch)=>{
        dispatch({
            type: REMOVE_DATA_AFTER_PAYMENT
        })
    }
}