import { FETCH_CURRENCIES_SUCCESS, FETCH_CURRENCY_CONVERSION_FAILURE, FETCH_CURRENCY_CONVERSION_REQUEST, FETCH_CURRENCY_CONVERSION_SUCCESS } from "./conversionTypes"

const initialState = {
    loading: false,
    paymentCurrencies:[],
    convertedValue:[]
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_CURRENCY_CONVERSION_REQUEST:
            return {
                ...state,
                loading: true,
                convertedValue:[]
            }
        case FETCH_CURRENCY_CONVERSION_SUCCESS:
            return{
                ...state,
                convertedValue: action.payload,
                loading: false
            }
        case FETCH_CURRENCIES_SUCCESS:
            return{
                ...state,
                paymentCurrencies:action.payload
            }
        case FETCH_CURRENCY_CONVERSION_FAILURE:
            return{
                ...state,
                loading:false
            }
        default:
            return state
    }
}

export default reducer