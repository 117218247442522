import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

const TabList = [
  {
    tabName: 'Best',
    name: 'best',
    points: 'best_price_in_points'
  },
  {
    tabName: 'Cheapest',
    name: 'cheapest',
    points: 'cheapest_price_in_points'
  },
  {
    tabName: 'Quickest',
    name: 'quickest',
    points: 'quickest_price_in_points'
  }
]
class FilterModal extends Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }

  componentDidMount() {
    const { stops, searchOrder } = this.props
    this.setState((state) => ({ ...state, stops, searchOrder }))
  }

  handleStops = (e) => {
    const stops = { ...this.state.stops, [e.target.name]: { ...this.state.stops[e.target.name], checked: e.target.checked } }
    this.setState((state) => ({ ...state, stops }))
  }

  changeOrder = (data) => {
    this.setState((state) => ({ ...state, searchOrder: data.name }))
  };

  handleSubmit = () => {
    this.props.handleOk(this.state)
  };

  handleClear = () => {
    const stops = { ...this.state.stops }
    Object.keys(stops).forEach(data => {
      stops[data].checked = false
    })
    this.setState((state) => ({ ...state, stops }))
  }

  render() {
    const { stops } = this.state
    const { show, showModal, sort, clear, filterData, type, stopsFilters, handleStops,searchResults,t } = this.props;
    return (
      <>
        {/* mobile modal  */}
        <Modal
          show={show}
          backdrop="static"
          keyboard={true}
          aria-labelledby="contained-modal-title-vcenter"
          className="d-block d-md-none policymodal  animate-bottom custom-scrollbar "
          scrollable={true}
        >
          <Modal.Header>
            <h4 className="mb-auto col-10  fs-md-bolder  pl-0 px-2">
              {t(type)}
            </h4>
            <div
              className="justify-content-end fs-medium color-green"
              onClick={showModal}
            >
              {t("Cancel")}
            </div>
          </Modal.Header>
          <Modal.Title className="text-center"></Modal.Title>
          <Modal.Body className="m-0 p-0">
            <ul className="px-4 pb-3  pt-0 stops-info">
              {type === 'Stops' ?
                <>
                  {stops && stopsFilters.map((stop, i) =>
                    <li className="d-flex justify-content-between sidebarinput my-2">
                      <div className="">
                        <Form.Check inline onChange={this.handleStops} checked={stops[stop.value]?.checked} label={t(stop.name)} name={stop.value} type='checkbox' />
                      </div>
                      {/* <div>
                        <span>11,490 Points</span>
                      </div> */}
                    </li>
                  )}
                </> :
                <>
                  {TabList?.map((data, i) => (
                    <li key={i} className="d-flex my-3 align-items-center justify-content-between">
                      <div className="custom-radio">
                        <input
                          name="tripTypeCheckbox1"
                          type="radio"
                          id={`${i}tripTypeCheckbox`}
                          className="mr-3"
                          checked={data.name === this.state.searchOrder}
                          onClick={() => this.changeOrder(data)}
                        />
                        <label htmlFor={`${i}tripTypeCheckbox`}><span>{t(data.tabName)}</span></label>
                      </div>
                      <span>{searchResults?.filtered_metadata?.fare_local && searchResults?.filtered_metadata?.fare_local?.[data.points]} {t("Points")}</span>
                    </li>
                  ))}
                </>
              }
            </ul>
            <div className="pb-5 px-3 d-flex">
              <button
                onClick={() => {
                  if (type === 'Stops') {
                    this.handleClear()
                  }
                }}
                className="btn filterButton2 w-25"
              >
                {t("Clear")}
              </button>
              <button
                onClick={() => {
                  this.handleSubmit()
                }}
                className="btn primary-btn ml-3 w-25"
              >
                {t("Done")}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default withTranslation()(FilterModal)
