import { FETCH_OFFERS_FAILURE, FETCH_OFFERS_REQUEST, FETCH_OFFERS_SUCCESS,FETCH_OFFER_DETAIL,FETCH_TERMS_AND_CONDITIONS,SORT_OFFERCARDS, FETCH_OFFER_DETAIL_SUCCESS, SORT_OFFERCARDS_SUCCESS} from "./offerTypes"

const initialState = {
    offersList:[],
    category:[],
    loading: true,
    error:'',
    filteredCards : [],
    sortBy:'Date',
    offerDetails:[],
    termsAndConditions:false,
    categoryName:'All',
    offerDetailsLoading:true,
    featuredOffers:[]

}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_OFFERS_REQUEST:
            
            return {
                ...state,
                loading:true,
                error:''
            }
        case FETCH_OFFERS_SUCCESS:
            return {
                ...state,
                loading:false,
                offersList:action.payload.data,
                category:action.payload.category,
                featuredOffers: action.payload.featuredOffers,
                error:''
            }
        case FETCH_OFFERS_FAILURE:
            return {
                ...state,
                offerDetails:[],
                offerDetailsLoading:false,
                loading:false,
                error:action.payload
            }
            case SORT_OFFERCARDS:
                return {
                    ...state,
                    ...state,
                loading:true,
                error:action.payload
                }
                case SORT_OFFERCARDS_SUCCESS:
                    return {
                        ...state,
                        filteredCards : action.payload.cards,
                        sortBy : action.payload.sortBy,
                        categoryName:action.payload.categoryName, 
                         loading:false,

                    }
                    case FETCH_OFFER_DETAIL:
                        return {
                            ...state,
                            offerDetailsLoading:true,
                            error: ''
                        }
                        case FETCH_OFFER_DETAIL_SUCCESS:
                            return {
                                ...state,
                                offerDetails:action.payload.offerDetails,
                                offerDetailsLoading:false,
                                error:''
                            }
        case FETCH_TERMS_AND_CONDITIONS:
            return{
                ...state,
                termsAndConditions : action.payload.termsAndConditions
            }
        default: return state
    }
}

export default reducer;
