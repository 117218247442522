import React, { Component } from "react";
import { Form, FormControl, InputGroup, Modal } from "react-bootstrap";
import { withTranslation } from 'react-i18next';

 class AirportAirlines extends Component {
  constructor(props) {
    super(props);
    this.state = {
      airlines: [],
      allselected: false
    };
  }
  componentDidMount() {
    this.setData()
  }

  setData = () => {
    const airlines = { ...this.props.airlines }
    const allselected = Object.values(airlines).every(data => data)
    this.setState((state) => ({ ...state, airlines, allselected }))
  }

  handleSelectAll = (e) => {
    const airlines = { ...this.state.airlines }
    console.log('airlines', airlines);
    Object.keys(airlines).forEach(key => {
      airlines[key] = e.target.checked
    })
    this.setState((state) => ({ ...state, allselected: e.target.checked, airlines }))
  };

  handleClear = () => {
    this.handleSelectAll({ target: { checked: false } })
  }

  handleAirline = (e, name, allChecked) => {
    let airlines = { ...this.state.airlines }

    if (allChecked) {
      Object.keys(airlines).forEach(key => {
        airlines[key] = true
      })
    } else {
      airlines = { ...this.state.airlines, [name]: e.target.checked }
    }
    const allselected = Object.values(airlines).every(data => data)
    this.setState((state) => ({ ...state, allselected, airlines }))
  }

  handleSubmit = () => {
    this.props.handleOk(this.state)
  };

  render() {
    const { allselected, airlines } = this.state
    const { show, showModal, shopover, type, sort, clear, title, airlinesData, t } = this.props;
    return (
      <>
        <Modal
          closeButton
          show={show}
          backdrop="static"
          //   onHide={showModal}
          keyboard={true}
          aria-labelledby="contained-modal-title-vcenter"
          className="d-block d-md-none filterModal animate-bottom"
          scrollable={true}
          style={{ "overflow-y": "auto", top: "14%" }}
        // animation={}
        >
          <Modal.Header>
            <h4 className="mb-auto col-10  fs-md-bolder  pl-0">
              {`${t(title)}`}
            </h4>
            <div
              className="justify-content-end fs-medium color-green"
              onClick={showModal}
            >
              {t("Cancel")}
            </div>
          </Modal.Header>
          <Modal.Title className="text-center"></Modal.Title>
          <Modal.Body className="m-0">
            {/* <div className="d-flex mb-3 ml-auto">
              <input className={"inline mr-2 checkbox-blue"} type="checkbox" checked={allselected} onChange={this.handleSelectAll} />
              <h4 className="mx-3 fs-medium">{t("Select All")}</h4>
            </div> */}
            {/* <div className="my-3">
              <h4
                className="color-blue
              "
              >
                {t("Stopovers")}
              </h4>
            </div> */}
            <div>
              {type === 'airlines' ?
                <>
                  {airlinesData?.map(airport => (
                    <div className="d-flex mb-3">
                      <Form.Check inline checked={airlines[airport.code]} onChange={(e) => this.handleAirline(e, airport.code)} name="group1" type='checkbox'
                        className="color-blue font-weight-bold inline mr-2 checkbox-blue"
                      />
                      <h4 className="mx-3 fs-medium">
                        {airport.name}
                      </h4>
                    </div>
                  ))
                  }
                </> :
                <>
                  {shopover.map((airport, index) => (
                    <div className="d-flex mb-3">
                      <input className="inline mr-2 checkbox-blue" type="checkbox" />
                      <h4 className="mx-3 fs-medium" value={index}>
                        {airport}
                      </h4>
                    </div>
                  ))}
                </>
              }
            </div>
            <div className="pb-5 px-3 d-flex justify-content-between">
              <button
                onClick={() => {
                  this.handleClear()
                  // showModal();
                }}
                className="btn filterButton2 w-25"
              >
                {t('Clear')}
              </button>
              <button
                onClick={() => {
                  this.handleSubmit();
                }}
                className="btn ml-3 primary-btn w-25"
              >
                {t('Done')}
              </button>
            </div>
          </Modal.Body>
        </Modal>
      </>
    );
  }
}

export default (withTranslation()(AirportAirlines));