import React, {Component} from "react";
import loaderImg from '../../assests/img/TravelShop/loading.svg'

class Loader extends Component {
    constructor(props) {
      super(props);
      this.state = { 
          compenent:null
       }
    }
    render() {
      return (
        <>
          <div className="loader-wrap">
              <img loading='lazy' decoding='async' src={loaderImg} alt=""/>
          </div>
        </>
      );
    }
  }
  export default Loader;