import React, { Component } from 'react';
import { Link } from 'react-router-dom';

export class Marchendize extends Component {
    constructor(props) {
      super(props)
    
      this.state = {
         
      }
    }
    
  render() {
    const {card, categoryName} = this.props;
    return <Link to={`./${categoryName}/${card.name}`}>
        <div style={{cursor:'pointer'}}>
                <img className="img-fluid w-100 mt-2" src={card?.img && card?.img }    alt={card?.name} />
                <h5 className="text-muted bold mt-3" style={{ fontSize:'1.1rem'}}>{card?.name}</h5>
            </div>
    </Link>

  }
}

export default Marchendize;
