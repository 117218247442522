import axios from "axios"
import { FETCH_CURRENCIES_SUCCESS, FETCH_CURRENCY_CONVERSION_FAILURE, FETCH_CURRENCY_CONVERSION_REQUEST, FETCH_CURRENCY_CONVERSION_SUCCESS } from "./conversionTypes"
import { REACT_APP_baseUrl } from 'utils/app-constants';
import secretKeyGenerator from "utils/serectKeyGenerator";


export const fetchCurrencyConversion = (payload) =>{
    return (dispatch) => {
        dispatch({type: FETCH_CURRENCY_CONVERSION_REQUEST})
        axios.post(`${REACT_APP_baseUrl}/giftcard_units/get_conversion_rate`,payload,{
            params: {
                "secret_key":secretKeyGenerator(payload)
            }
        })
        .then(response => {
            if(response.data?.code === 200){
                dispatch({
                    type:FETCH_CURRENCY_CONVERSION_SUCCESS,
                    payload: response.data.data
                })
            }
            else{
                dispatch({
                    type: FETCH_CURRENCY_CONVERSION_FAILURE,
                    payload: response.data.message
                })
            }
        })
        .catch(err => {
            dispatch({
                type: FETCH_CURRENCY_CONVERSION_FAILURE,
                payload: 'Something went wrong!'
            })
        })
    }
}


export const fetchCurrencies = () => {
    return (dispatch) => {
        axios.get(`${REACT_APP_baseUrl}/giftcard_units/get_payment_currencies`)
        .then(response => {
            dispatch({
                type:FETCH_CURRENCIES_SUCCESS,
                payload: response.data.data?.payment_currencies
            })
        })
        .catch(err => {
            console.log("Error:",err);
        })
    }
}