import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';


class InputFeild extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            compenent:null
         }
    }
    getInputCompenent(data){
        switch (data.type) {
            case "email":
            case "text": 
                return(
                        <input 
                            className="py-3 input-field rounded-0 border outline-color-skyblue"
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            type={data.type} 
                            value={data.value}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}
                            autoComplete="off"
                            />
                        
                )
            case "tel":
                return(
                   
                        <div className="d-flex col-12 p-0">
                            <select 
                                className="btn border bg-light rounded-0 caret flex-shrink-0" 
                                name="countryCode" 
                                value="saudi"
                                onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}>
                                <option value="saudi">+891</option>
                            </select>
                            <input 
                                className="flex-grow-1 py-3 rounded-0 border"
                                id={data.id}
                                title={data.title}
                                aria-label={data.ariaLabel}
                                tabIndex={data.tabIndex}
                                readOnly={data.readOnly}
                                type={data.type} 
                                value={data.value}
                                onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}/>
                        </div>
                   
                )
            case "dropdown":
                return(
                    // <select className="w-100 py-3 px-3 rounded-0 border outline-color-skyblue caret-down arrows dis" 
                        <select className="arrows dis border w-100 py-2" 
                            id={data.id}
                            title={data.title}
                            aria-label={data.ariaLabel}
                            tabIndex={data.tabIndex}
                            readOnly={data.readOnly}
                            value={data.value}
                            onChange={(e)=>{this.props.onChange(e.target.value,data.id)}}>
                        {
                            data.options.map(option=>(
                                <option 
                                    className="d-block w-100"
                                    value={option}
                                    key={option}>
                                    {option}
                                </option>
                            ))
                        }
                    </select>
                 
                )
                
            default:
            break;
        }
    }
    render() { 
        const data=this.props.data
        const {t}=this.props
        return ( 
            <div className="w-100 d-flex flex-column py-2">
                <label 
                    // className="fw-bold"
                    htmlFor={data.id}
                    >{`${t(data.title)}`}
                    </label>
                    {this.getInputCompenent(data)}
                    {(data.errorMessage && data.value==="")&&
                        <h6 className="text-danger py-2 text-center m-0" style={{backgroundColor:"rgb(255, 230, 230)"}}>
                            {`${t(data.errorMessage)}`}
                        </h6>
                    }
            </div>
         );
    }
}
 
export default (withTranslation() (InputFeild));