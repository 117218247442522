
import { FETCH_MOBILECOUNTRIES_FAILURE, FETCH_MOBILECOUNTRIES_REQUEST, FETCH_MOBILECOUNTRIES_SUCCESS,
    FETCH_SERVICES_FAILURE, FETCH_SERVICES_REQUEST, FETCH_SERVICES_SUCCESS, FETCH_TERMS_TOPUP} from "./mobileType"

const initialState = {
    mobileCountries:[],
    loading: true,
    error:'',
    services:[],
    terms:[]
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_MOBILECOUNTRIES_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_MOBILECOUNTRIES_SUCCESS:
            return {
                ...state,
                loading:false,
                mobileCountries:action.payload
            }
        case FETCH_MOBILECOUNTRIES_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
            case FETCH_SERVICES_REQUEST:
            return {
                mobileCountries:[],
                error:'',
                services:[],
                terms:[],
                loading: true
            }
        case FETCH_SERVICES_SUCCESS:
            return {
                ...state,
                loading:false,
                services:action.payload
            }
        case FETCH_SERVICES_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
         case FETCH_TERMS_TOPUP:
            return{
                ...state,
                terms:action.payload
            }
        default: return state
    }
}

export default reducer;