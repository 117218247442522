import { FETCH_EMAGAZINES_FAILURE, FETCH_EMAGAZINES_REQUEST, FETCH_EMAGAZINES_SUCCESS } from "./eMagazinesTypes"

const initialState = {
    eMagazines:[],
    loading: true,
    error:''
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_EMAGAZINES_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_EMAGAZINES_SUCCESS:
            return {
                loading:false,
                eMagazines:action.payload
            }
        case FETCH_EMAGAZINES_FAILURE:
            return {
                loading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;