import axios from "axios";
import { REACT_APP_baseUrl } from "utils/app-constants";
import { CONFIRM_ACC_REQ, CONFIRM_ACC_SUCCESS, CONFIRM_ACC_FAILURE } from "./confirmAccTypes";

export const AccountReq = () =>{
    return{
        type : CONFIRM_ACC_REQ
    }
}

export const AccountSuccess = (data) =>{
    return{
        type : CONFIRM_ACC_SUCCESS,
        payload : data
    }
}

export const AccountFailure = (data) =>{
    return{
        type : CONFIRM_ACC_FAILURE,
        payload : data
    }
}

export const ConfirmAccount = (token) =>{
    return(dispatch)=>{
        dispatch(AccountReq())
        axios.put(`${REACT_APP_baseUrl}/accounts/confirmations/confirm_account`,
        {
            token:token
        }
        ).then(res =>{
            if(res.data?.data?.user_email?.status === "VERIFIED"){
                dispatch(AccountSuccess(res.data?.data?.user_email?.status))
            }else{
                dispatch(AccountFailure("Invalid token"))
            }
        }).catch(err =>{
            dispatch(AccountFailure("Something went wrong"))
        })
    }
}