import React, { Component } from "react";
import { Accordion, Button, Card, Col, Row } from "react-bootstrap";
import PlaneTicketBlue from "../../../assests/img/TravelShop/plane-ticket-blue.svg";
import arrow from "../../../assests/img/TravelShop/arrow-left-right.svg";
import calendar from "../../../assests/img/TravelShop/mywallet-calendar.png"
import downloadTicket from "../../../assests/img/TravelShop/download-ticket.png"
import shareTicket from "../../../assests/img/TravelShop/share.png"
import grayTicket from "../../../assests/img/TravelShop/grey-plane.svg"
import AddToCalendarHOC , {SHARE_SITES} from 'react-add-to-calendar-hoc';
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import moment from "moment";
import searchIcn from '../../../assests/img/header/icon-only-search.png'
import { REACT_APP_baseUrl } from "../../../utils/app-constants";
import DownloadTicket from "./DownloadTicket";
import './../../TravelShop/TravelShop.css'
import ReactTooltip from "react-tooltip";

const ATCDropdown = (args) => (
  <div style={{color:'white',position:'absolute',marginTop:'27px'}}>
  {
    args.children.map((link, i) => (
      <li key={i} style={{color:'black'}}>{link}</li>
    ))
  }
</div>
)

const ATCWrapper = (args) => (
  <a onClick={args.onClick} className="atc-item" href="/">
    {args.children}
  </a>
);

const AddToCalendarDropdown = AddToCalendarHOC(ATCWrapper, ATCDropdown);


class TravelSection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dropdownFilter:"ALL",
      upcomingToggle:true,
      completedToggle:false,
      ticketLink:null,
      calendarEvent: {
        title: '',
        description: '',
        location: '',
        startTime: '',
        endTime: ''
      },
      calendarEvent2: {
        title: '',
        description: '',
        location: '',
        startTime: '',
        endTime: ''
      },
      upcomingFlightDetails:null,
      pastFlightDetails:null,
      downloadTicketLoading:false
    };
  }

  componentDidMount(){
    window.scrollTo(0,0);
    this.props.comingFlights && this.flightDetails(this.props.comingFlights,'upcomingFlightDetails');
    this.props.pastFlights && this.flightDetails(this.props.pastFlights,'pastFlightDetails');
  }

  componentDidUpdate(prevProps){
    if(prevProps.comingFlights !== this.props.comingFlights){
      this.flightDetails(this.props.comingFlights,'upcomingFlightDetails')
    }
    if(prevProps.pastFlights !== this.props.pastFlights){
      this.flightDetails(this.props.pastFlights,'pastFlightDetails')
    }
  }

  addCalender=(flightSection)=>{
    let calender = { ...this.state.calendarEvent }
    let offset = moment().format("Z")
    let {outBoundFlight} = flightSection

      calender.title =  `Flight from ${outBoundFlight?.[0]?.origin?.city} to ${outBoundFlight?.[outBoundFlight?.length-1]?.destination?.city}` 
      calender.location = outBoundFlight?.[0]?.origin?.name;
      calender.startTime = outBoundFlight?.[0]?.departure_time?.concat(offset);
      calender.endTime = outBoundFlight?.[outBoundFlight?.length-1]?.arrival_time?.concat(offset);
      let date = moment(calender.startTime);
      let newDate = date.format('DD MMMM YYYY');
      calender.description = `You have a Flight on ${newDate} from ${outBoundFlight?.[0]?.origin?.city} to ${outBoundFlight?.[outBoundFlight?.length-1]?.destination?.city}`
      this.setState({ calendarEvent : {
        title: calender.title,
        description: calender.description,
        duration: new Date(calender.startTime)?.getHours() - new Date(calender.endTime)?.getHours(),
        location: calender.location,
        endDatetime: moment(calender.endTime).format("YYYYMMDDTHHmmssZ"),
        startDatetime: moment(calender.startTime).format("YYYYMMDDTHHmmssZ")
      } })
  }

  addCalender2=(flightSection)=>{
    let calender2 = { ...this.state.calendarEvent2 }
    let offset = moment().format("Z")
    let {inBoundFlight} = flightSection

      calender2.title =  `Flight from ${inBoundFlight?.[0]?.origin?.city} to ${inBoundFlight?.[inBoundFlight?.length-1]?.destination?.city}` 
      calender2.location = inBoundFlight?.[0]?.origin?.name;
      calender2.startTime = inBoundFlight?.[0]?.departure_time?.concat(offset);
      calender2.endTime = inBoundFlight?.[inBoundFlight?.length-1]?.arrival_time?.concat(offset);
      let date2 = moment(calender2.startTime);
      let newDate2 = date2.format('DD MMMM YYYY');
      calender2.description = `You have a Flight on ${newDate2} from ${inBoundFlight?.[0]?.origin?.city} to ${inBoundFlight?.[inBoundFlight?.length-1]?.destination?.city}`
      this.setState({ calendarEvent : {
        title: calender2.title,
        description: calender2.description,
        duration: new Date(calender2.startTime)?.getHours() - new Date(calender2.endTime)?.getHours(),
        location: calender2.location,
        endDatetime: moment(calender2.endTime).format("YYYYMMDDTHHmmssZ"),
        startDatetime: moment(calender2.startTime).format("YYYYMMDDTHHmmssZ")
      } })
      }

  flightDetails = (flightDetails,type) => {
    flightDetails?.forEach((flight) => {
      let inBoundFlight = [];
      let outBoundFlight  = [];
      flight?.segments?.forEach(e => {
        if(e.segment_indicator === 1){return outBoundFlight.push(e)};
        inBoundFlight.push(e)
     });
      flight['flightSection'] = {
        outBoundFlight : outBoundFlight,
        inBoundFlight : inBoundFlight    
    }
    flight['roundTripFlag'] = inBoundFlight.length !== 0
  })
    this.setState({[type]:flightDetails})
  }

  addTimes(segments) {
    let duration = 0;
    segments?.forEach(segment => {
      duration = duration + moment.duration(segment.duration).as('milliseconds')
    });
    let time = moment.utc(duration).format("HH:mm")?.split(":");
    return `${time[0]} h ${time[1]} m`
   }
   airlinesDetails = (data) => {
    let airlineName = [];
    let duplicateArr = [];
    data?.forEach(e=> {
      if(!duplicateArr.includes(e.airline_name)){
        duplicateArr.push(e.airline_name);
        airlineName.push([e.airline_name,e.airline_image])
      }
    })
    return airlineName
  }
  setDropdownFilter = (val) =>{this.setState({dropdownFilter:val})}

  searchFilter = (data) => {
    let filterValue = this.state.dropdownFilter;
    if(filterValue === 'ALL'){
      return data
    }
    let filteredData = data?.filter((element) => element.booking_status === this.state.dropdownFilter?.toLowerCase())
    return filteredData
  }

  render() {
    const {comingFlights, pastFlights} = this.props;
    const {upcomingFlightDetails,pastFlightDetails,dropdownFilter} = this.state
    let icon = "calender-icon" ;
    const { completedToggle , upcomingToggle,  } = this.state;
    const {t}= this.props

    return (
      <>
      <Accordion defaultActiveKey="1">
  <Card style={{width:"100%", border:"none"}}>
    <Card.Header className='bg-color-none d-flex justify-content-between p-0 align-items-center px-lg-4 '>
      {
        this.props.myWalletHomePage ? 
        <div className="fs-5 w-100 fw-bold rounded-0 color-blue py-3 d-flex justify-content-between align-items-center">
          {t("Flights")}
          <button onClick={this.props.showAll} className="color-green fs-6 btn">
            {t("Show All")} ({ upcomingFlightDetails && upcomingFlightDetails.length})
          </button>
        </div>
        
        : <Accordion.Toggle className=' no-outline d-flex justify-content-between w-100' as={Button} variant="button" eventKey="1" onClick={()=> this.setState({upcomingToggle: !upcomingToggle})}>
        <h5 className='text-muted'>{t("Upcoming Flights")}</h5>
          <h4 className='color-green fs-large' > {upcomingToggle  ? "-" : "+" } </h4>
        </Accordion.Toggle>
      }
    </Card.Header>
    <Accordion.Collapse eventKey="1">
      <Card.Body className='p-0'>
        {
            comingFlights.length > 0 ? <> 
             {/* Search Bar */}
        <div className={` ${this.props.disableSearchBar ? 'd-none' : 'd-flex'} mx-4 justify-content-between border-color-green`} style={{width:'fit-content'}}>
        {
              <div className="p-0">
              <button className="btn dropdown-toggle fw-bold h-100 color-green border-color-green d-flex justify-content-between align-items-center" type="button" data-toggle="dropdown">{t(dropdownFilter)}
              <span className="caret"></span></button>
              <ul className="dropdown-menu">
                  {["ALL","IN PROGRESS","SUCCESS","CANCELED"].map(item=>(
                      <li 
                          key={item} 
                          className=" cursor-pointer dropdown-item"
                          onClick={e=>this.setDropdownFilter(item)}
                      >
                          {t(item)}
                      </li>
                  ))}
              </ul>
          </div>        
        }
        {/* <button className="d-md-block d-none p-0 btn-primary border-0 " style={{width:"4rem"}}>
                    <img loading='lazy' decoding='async' src={searchIcn} className="img-fluid h-100 "  alt=""/>
                </button>
                <button className="d-md-none d-block p-0 border-0 bg-white" style={{width:"3rem"}}>
                    <img loading='lazy' decoding='async' src={searchIcn} className="img-fluid h-100 icon-white"  alt=""/>
                </button> */}
        </div>
      <Row style={{minHeight:'50vh'}} className={`d-flex footerMargin mx-2 ${this.props.myWalletHomePage && 'overflow-auto flex-nowrap'}`}>
          {this.searchFilter(upcomingFlightDetails)?.map((details) =>{
             const {outBoundFlight,inBoundFlight} = details?.flightSection || [];
            return (
              <Col
              className="d-flex justify-content-center my-3"
              lg={4}
              xs={12}
              md={12}
            >
              <Card style={{ width: "25rem" }} className='travelWalletCard pb-1'>
                <div style={{position:'relative'}} className="text-center p-3 d-flex justify-content-center">
                  <img loading='lazy' decoding='async'
                    alt="logo"
                    variant="top"
                    className="img-fluid color-blue mx-auto"
                    width="20%"
                    src={PlaneTicketBlue}
                  />
                  <div style={{position:'absolute',top:'0',right:'0',maxWidth:'40%'}} className="bg-light-gray border-0 text-capitalize p-2">
                  <h6 className="color-blue fw-bold" style={{fontSize:'0.9em'}}>{details.booking_status}</h6>
                  </div>
                </div>
                <Card.Body>
                  <Card.Title>
                    <div className="d-flex justify-content-center color-blue">
                      <div className="px-2">
                        <h5>{outBoundFlight?.[0]?.origin?.city_code}</h5>
                      </div>
                      <div>
                        <img loading='lazy' decoding='async' src={arrow} alt="" width="100%" />
                      </div>
                      <div className="px-2">
                        <h5>{outBoundFlight?.[outBoundFlight.length - 1]?.destination?.city_code}</h5>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text>
                    <div className="d-flex text-muted fs-regular justify-content-center">
                      <div>
                      {details.roundTripFlag ?  
                      `${moment(outBoundFlight?.[0]?.departure_time).format('MMM Do')} - ${moment(inBoundFlight?.[0]?.departure_time).format('MMM Do')} | ` : 
                      
                      `${moment(outBoundFlight?.[0]?.departure_time).format('MMM Do')} | `}

                      {" "}{details?.passenger_count} {t("Passenger")} {" | "} {details?.segments?.[0]?.cabin_class}
                      
                      </div>
                    </div>
                    <hr />
                    {/* Details Section */}
                    <div className="d-flex flex-column gap-1">
                            <div className="d-flex justify-content-between w-100">
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="px-2 fw-bold">
                                      <span>{outBoundFlight?.[0]?.origin?.city_code}</span>
                                    </div>
                                    <div>
                                      <img loading='lazy' decoding='async' src={grayTicket} alt="" width="100%" height="40%"/>
                                    </div>
                                    <div className="px-2 fw-bold">
                                      <span>{outBoundFlight?.[outBoundFlight.length - 1]?.destination?.city_code}</span>
                                    </div>
                                </div>
                                <div>
                                  </div>
                                </div>
                                <div className='d-flex px-2'>
                                  {
                                    this.airlinesDetails(outBoundFlight)?.map((e,index) => (
                                      <div className="d-flex align-items-center" key={index}>
                                          <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                          <span className='text-muted px-2'>{e[0]}</span>
                                      </div>
                                    ))
                                  }
                            </div>
                            <div className="d-flex fw-bold w-100 justify-content-between px-2">
                                <div className="">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(outBoundFlight?.[outBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {outBoundFlight?.length - 1 > 0 && <sup>+{outBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>{t("Duration")} {this.addTimes(outBoundFlight)}</small>
                                </div>
                            </div>
                  </div>
                    <hr />
                    {/* Second Section */}
                    {
                    details?.roundTripFlag && <div className="d-flex flex-column gap-1">
                    <div className="d-flex justify-content-between w-100">
                    <div className="d-flex justify-content-center align-items-center">
                                  <div className="px-2 fw-bold">
                                      <span>{inBoundFlight?.[0]?.origin?.city_code}</span>
                                    </div>
                                    <div>
                                      <img loading='lazy' decoding='async' src={grayTicket} alt="" width="100%" height="40%"/>
                                    </div>
                                    <div className="px-2 fw-bold">
                                      <span>{inBoundFlight?.[inBoundFlight.length - 1]?.destination?.city_code}</span>
                                    </div>
                                </div>
                        <div>
                          </div>
                        </div>
                        <div className='d-flex px-2'>
                                  {
                                    this.airlinesDetails(inBoundFlight)?.map((e,index) => (
                                      <div className="d-flex align-items-center" key={index}>
                                          <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                          <span className='text-muted px-2'>{e[0]}</span>
                                      </div>
                                    ))
                                  }
                            </div>
                            <div className="d-flex fw-bold w-100 justify-content-between px-2">
                                <div className="">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(inBoundFlight?.[inBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {inBoundFlight?.length - 1 > 0 && <sup>+{inBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>{t("Duration")} {this.addTimes(inBoundFlight)}</small>
                                </div>
                            </div>
                         </div>
                         }
                     {
                      details.booking_status === 'success' && <div className="mywalletTravelSection mb-3 mt-4">
                      
                      
                      <div onClick={()=>{this.addCalender(details.flightSection)}} className='d-flex mywalletTravelImages justify-content-center py-2 rounded' data-tip data-for={details?.pnr}>
                      <AddToCalendarDropdown
                        items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
                        className='add-to-calendar-dropdown calendar-icon'
                        linkProps={{
                          className: "atc-dropdown-title"
                        }}
                        event={this.state.calendarEvent}
                        buttonText={'Add'}
                      />
                      <ReactTooltip id={details?.pnr} place="top" effect="solid" className='p-1'>
                        {t("Add to calendar")}
                      </ReactTooltip>
                      </div>


                      {details.roundTripFlag === true && 
                      <div className="calendar2">
                      <div onClick={()=>{this.addCalender2(details.flightSection)}} className='d-flex mywalletTravelImages justify-content-center py-2 rounded' data-tip data-for={details?.last_update}>
                      <AddToCalendarDropdown
                        items={[SHARE_SITES.GOOGLE, SHARE_SITES.ICAL]}
                        className='add-to-calendar-dropdown calendar-icon'
                        linkProps={{
                          className: "atc-dropdown-title"
                        }}
                        event={this.state.calendarEvent2}
                        buttonText={'Add'}
                      />
                      </div>
                        <ReactTooltip id={details?.last_update} place="top" effect="solid" className='p-1'>
                          {t("Add to calendar 2")}
                        </ReactTooltip>
                      </div>
                      }


                      <DownloadTicket details={details} img={downloadTicket} baseUrl={REACT_APP_baseUrl}/>
                      
                      </div>
                    }
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
            )
          } )}
        </Row>
            </> : <h4 className="text-center">{t("No Flights Available")}</h4>
        }
     
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  <hr width="97%" />
  {/* completed Flights  */}
  {
    !this.props.myWalletHomePage && <Card style={{width:"100%", border:"none"}}>
    <Card.Header className='bg-color-none d-flex justify-content-between p-0 align-items-center px-lg-4 '>
      <Accordion.Toggle className=' no-outline d-flex justify-content-between w-100' as={Button} variant="button" eventKey="0" onClick={()=> this.setState({completedToggle: !completedToggle})}>
      <h5 className='text-muted'>{t("Completed Flights")}</h5>
      <h4 className='color-green fs-large'> {completedToggle  ? "-" : "+" } </h4>
      </Accordion.Toggle>
    </Card.Header>
    <Accordion.Collapse eventKey="0">
      <Card.Body className='p-0'>
      <Row className="d-flex footerMargin mx-2">
          {pastFlightDetails?.map((details) => {
            const {outBoundFlight,inBoundFlight} = details?.flightSection || []
            return (
            <Col
              className="d-flex justify-content-center my-3"
              lg={4}
              xs={12}
              md={12}
            >
              <Card style={{ width: "25rem" }} className='travelWalletCard'>
                <div className="text-center p-3">
                  <img loading='lazy' decoding='async'
                    alt="logo"
                    variant="top"
                    className="img-fluid color-blue"
                    width="20%"
                    src={PlaneTicketBlue}
                  />
                </div>
                <Card.Body>
                  <Card.Title>
                    <div className="d-flex justify-content-center color-blue">
                      <div className="px-2">
                       <h5>{outBoundFlight?.[0]?.origin?.city_code}</h5>
                      </div>
                      <div>
                        <img loading='lazy' decoding='async' src={arrow} alt="" width="100%" />
                      </div>
                      <div className="px-2">
                      <h5>{outBoundFlight?.[outBoundFlight.length - 1]?.destination?.city_code}</h5>
                      </div>
                    </div>
                  </Card.Title>
                  <Card.Text>
                    <div className="d-flex text-muted fs-regular justify-content-center">
                    <div>
                      {details.roundTripFlag ?  
                      `${moment(outBoundFlight?.[0]?.departure_time).format('MMM Do')} - ${moment(inBoundFlight?.[inBoundFlight.length - 1]?.departure_time).format('MMM Do')} | ` : 
                      
                      `${moment(outBoundFlight?.[0]?.departure_time).format('MMM Do')} | `}

                      {" "}{details?.passenger_count} Passenger {" | "} {details?.segments?.[0]?.cabin_class}
                      
                      </div>
                    </div>
                    <hr />
                    {/* Details Section */}
                    <div className="d-flex flex-column gap-1">
                            <div className="d-flex justify-content-between w-100">
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="px-2 fw-bold">
                                      <span>{outBoundFlight?.[0]?.origin?.city_code}</span>
                                    </div>
                                    <div>
                                      <img loading='lazy' decoding='async' src={grayTicket} alt="" width="100%" height="40%"/>
                                    </div>
                                    <div className="px-2 fw-bold">
                                      <span>{outBoundFlight?.[outBoundFlight.length - 1]?.destination?.city_code}</span>
                                    </div>
                                </div>
                                <div>
                                  </div>
                                </div>
                                <div className='d-flex px-2'>
                                  {
                                    this.airlinesDetails(outBoundFlight)?.map((e,index) => (
                                      <div className="d-flex align-items-center" key={index}>
                                          <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                          <span className='text-muted px-2'>{e[0]}</span>
                                      </div>
                                    ))
                                  }
                            </div>
                            <div className="d-flex fw-bold w-100 justify-content-between px-2">
                                <div className="">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(outBoundFlight?.[outBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {outBoundFlight?.length - 1 > 0 && <sup>+{outBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>Duration {this.addTimes(outBoundFlight)}</small>
                                </div>
                            </div>
                  </div>
                    <hr />
                    {/* Second Section */}
                    {
                    details?.round_trip_flag && <div className="d-flex flex-column gap-1">
                    <div className="d-flex justify-content-between w-100">
                    <div className="d-flex justify-content-center align-items-center">
                                  <div className="px-2 fw-bold">
                                      <span>{inBoundFlight?.[0]?.origin?.city_code}</span>
                                    </div>
                                    <div>
                                      <img loading='lazy' decoding='async' src={grayTicket} alt="" width="100%" height="40%"/>
                                    </div>
                                    <div className="px-2 fw-bold">
                                      <span>{inBoundFlight?.[inBoundFlight.length - 1]?.destination?.city_code}</span>
                                    </div>
                                </div>
                        <div>
                          </div>
                        </div>
                        <div className='d-flex'>
                                  {
                                    this.airlinesDetails(inBoundFlight)?.map((e,index) => (
                                      <div className="d-flex align-items-center" key={index}>
                                          <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                          <span className='text-muted px-2'>{e[0]}</span>
                                      </div>
                                    ))
                                  }
                            </div>
                            <div className="d-flex fw-bold w-100 justify-content-between px-2">
                                <div className="">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(inBoundFlight?.[inBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {inBoundFlight?.length - 1 > 0 && <sup>+{inBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>Duration {this.addTimes(inBoundFlight)}</small>
                                </div>
                            </div>
          </div>
                  }
                    <div className="d-flex justify-content-between gap-2 mb-3 mt-4">
                      <div onClick={this.downloadPDF} className='d-flex mywalletTravelImages-gray justify-content-center py-2 rounded'><img loading='lazy' decoding='async' src={downloadTicket} alt="downloadTicket" /></div>
                      <div className='d-flex mywalletTravelImages-gray justify-content-center py-2 rounded'><img loading='lazy' decoding='async' src={calendar} alt="calendar" /></div>
                      <div onClick={this.sharePdf} className='d-flex mywalletTravelImages-gray justify-content-center py-2 rounded'><img loading='lazy' decoding='async' src={shareTicket} alt="shareTicket" /></div>
                    </div>
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          )})}
        </Row>
      </Card.Body>
    </Accordion.Collapse>
  </Card>
  }
</Accordion>
      </>
    );
  }
}
const mapStateToProps = state => {
  return {
      comingFlights: state.myWallet.comingFlights,
      pastFlights:state.myWallet.pastFlights
  }
}
export default connect(mapStateToProps)(withTranslation() (TravelSection));
