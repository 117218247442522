import React, { Component } from 'react'
import ModalWrapper from './ModalWrapper'
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';
import { ReactComponent as PasswordChanged } from "../../../assests/img/Login/password-changed-successfully.svg";
import { ReactComponent as TokenInvalid } from "../../../assests/img/Login/token-invalid.svg";
import { connect } from 'react-redux';
import { SignUpMessageClear } from 'Redux/auth/authActions';


class RedemptionSuccesfullForLogged extends Component {
  constructor(props) {
    super(props);
    this.state = {
      
    };
  }
  
  handleOK = () =>{
    this.props.SignUpMessageClear();
  }

  render() {
      
    const { lang, t,responseMessage } = this.props;

    return (
      <div>
            <ModalWrapper show={this.props.show} >
            <div className="bg-white muted-color mx-auto px-5 LoginModel width-35" >
            <div className="text-center mt-5">  
              <div className='redempton-pop'>
                {
                  this.props.redeemSuccess ? <PasswordChanged/> : <TokenInvalid/>
                }
              </div>
              {
                this.props.redeemSuccess ? <>
                  <h5 className='fw-bold mt-3'>{t("Redeemed Successfully")}</h5>
                  <p>{t("You have redeemed your Reward no")}: {this.props.giftCardNo} {t("and points have been added to your account.")}</p>
                </> : 
                <h5 className='fw-bold mt-3'>{t(responseMessage || 'Your card has been already redeemed')}</h5>
              }
            </div>
              <div className="text-center mb-2 mt-3">
              <button className="btn btn-sky text-light w-100 " onClick={this.props.closeModal}>
                    {t("Start Shopping")}
                  </button>
              <br />
            </div>
              
          </div>
            </ModalWrapper>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    email:state?.auth?.email
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    SignUpMessageClear : () => dispatch(SignUpMessageClear())
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(withTranslation()(RedemptionSuccesfullForLogged))