import { FETCH_SOFTWARE_GAMES_DETAILS, FETCH_SOFTWARE_GAMES_FAILURE,FETCH_SOFTWARE_GAMES_REQUEST, FETCH_SOFTWARE_GAMES_SUCCESS, FETCH_SOFTWARE_GAMES_TERMS,FETCH_SOFTWARE_GAMES_DETAILS_SUCCESS } from "./SoftwareTypes"


const initialState = {
    // experienceCards:[],
    softwareGamesCards:[],
    loading: true,
    error:'',
    softwareGameseDetails:[],
    softwareGamesTerms:[],
    // experienceImages:undefined,
    detailsLoading:true,
    // imgLoading:true,
    // featuredExpCards:[]
}
const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_SOFTWARE_GAMES_REQUEST:
            return {
                ...state,
                loading:true
            }
        // case FETCH_IMAGES_REQUEST:
        //     return{
        //         ...state,
        //         imgLoading:true,
        //         experienceImages:undefined
        //     }
        case FETCH_SOFTWARE_GAMES_SUCCESS:
            return {
                ...state,
                loading:false,
                softwareGamesCards:action.payload.data,
                // featuredsoftwareGamesCards: action.payload.featuredCards
            }
        case FETCH_SOFTWARE_GAMES_FAILURE:
            return {
                ...state,
                loading:false,
                detailsLoading: false,
                error:action.payload
            }
            case FETCH_SOFTWARE_GAMES_DETAILS:
                return{
                    ...state,
                    detailsLoading: true,
                    softwareGameseDetails:[],
                    softwareGamesTerms:[],
                    error:''
                }
                case FETCH_SOFTWARE_GAMES_DETAILS_SUCCESS:
                return{
                    ...state,
                    softwareGameseDetails:action.payload.softwareGameseDetails,
                    detailsLoading: false
                }
        case FETCH_SOFTWARE_GAMES_TERMS:
            return{
                ...state,
                softwareGamesTerms:action.payload.softwareGamesTerms,
                
            }
        // case FETCH_SOFTWARE_GAMES_IMAGES:
        //     return{
        //         ...state,
        //         experienceImages:action.payload.experienceImages,
        //         imgLoading:false
        //     }
        // case FETCH_IMAGES_FAILURE:
        //     return{
        //         ...state,
        //         imgLoading:false,
        //         error:action.payload
        //     }
        default: return state
    }
   
}

export default reducer;