import './ProductDetailsContent.css';
// import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { currancyToPoints, pointsToCurrancy } from '../../utils/pointsConversion';
import { connect } from 'react-redux';
// import card from '../../assests/img/card.svg'
export function ProductDetailsContent(props) {
  // const {
  //   product_name,
  //   brand_name,

  // } = props;
  let availabePoints = props.pointBalance || 0
  let missingPoints = currancyToPoints(props.total_cost) - availabePoints
 let clicked = props.variantsData && props.variantsData.length > 0 && props.variantsData?.map(x=>x.selected)
 const value = clicked && props.prices && props.prices.length>0 && props.prices.filter((val) => val.variants["Color"] === clicked[0] && val.variants["Internal Memory"] === clicked[1] )
 // eslint-disable-next-line 
 const points =   value && value.length > 0 && value[0].total_cost 
 const {t}= props
 
 const numberWithCommas = (val) => {
  //  let x = parseInt(val)
  //   let y = x.toFixed(2)
    return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}
const numberWithCom = (val) => {
  return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

return <div className="pt-details-content">
    <div className="pt-header-section  py-1">
    <h4 className="headings"> { props.lang === 'en' ?props.product_name:props.product_name_arabic }  {props.value && props.value.Memory}  {props.value && props.value.color}</h4>
       {/* <span className="pt-header-subtitle">{t("by")}: &nbsp;{ props.lang==='en'? props.brand_name : props.brand_name_arabic}</span> */}
    </div>
    <div className="pt-price-section">
    <span className="pt-title"></span>  
    <h5 className="pt-price-value">{numberWithCommas(currancyToPoints(props.total_cost))} {t("Points")}</h5>
    </div>
   <div className='pt-points-section mt-1'>
   {
          missingPoints> 0 && <div className="d-flex">
          <span className={`${props.lang === 'ar' ? 'text-right' : ''} pt-title`}>{t("Missing Points")}{":"}</span>
          <div><h5 style={{color:'#F76D70'}} className="ml-1 pt-value"> {numberWithCommas(missingPoints.toFixed(2))}</h5></div>
        </div>
        }
        {
           missingPoints > 0 &&  <div className="">
           <span style={{fontWeight:'500'}} className={`headings ${props.lang === 'ar' ? 'text-right' : ''}`}>{numberWithCommas(missingPoints.toFixed(2))} {t("Points")} ({numberWithCom(pointsToCurrancy(missingPoints))} {t(props.selectedCountry)})</span>
            <div className="">{t("to be paid by Credit Card")}
                         
         </div>
         </div>
        }
   </div>
  </div>;
}
const mapStateToProps = state => {
  return {
      pointBalance:state.point.pointBalance,
      selectedCountry:state.countries.convertionRate?.currency_code
  }
}
export default  connect(mapStateToProps)(withTranslation() (ProductDetailsContent));
