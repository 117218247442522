export const FETCH_DETAILS_REQUEST ='FETCH_DETAILS_REQUEST'
export const FETCH_DETAILS_FAILURE ='FETCH_DETAILS_FAILURE'
export const CATEGORY_GIFTCARDS ='CATEGORY_GIFTCARDS'
export const CATEGORY_OFFERS ='CATEGORY_OFFERS'
export const CATEGORY_EXPERIENCES = 'CATEGORY_EXPERIENCES'
export const CATEGORY_MERCHANDISE = 'CATEGORY_MERCHANDISE'
export const RECORD_BY_CATEGORIES = 'RECORD_BY_CATEGORIES'
export const ALL_CATEGORIES = 'ALL_CATEGORIES'
export const FETCH_RECORD_REQUEST = 'FETCH_RECORD_REQUEST'
export const HASSAD_MALL_CATEGORIES = "HASSAD_MALL_CATEGORIES"
export const MODULE_INFO = 'MODULE_INFO'


