import {RESET_REQUEST,RESET_SUCCESS,RESET_FAILURE, RESET_CLEAR} from "./ResetTypes"

const initialState = {
    loading: false,
    msg:null,
    show:false
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case RESET_REQUEST:
            return {
                ...state,
                loading:true,
            }
        case RESET_SUCCESS:
            return {
                ...state,
                loading:false,
                msg:action.payload,
                show:true
            }
        case RESET_FAILURE:
            return {
                ...state,
                loading:false,
                msg:action.payload,
            }
        case RESET_CLEAR:
            return {
                ...state,
                loading:false,
                msg:"",
            }
        default: return state
    }
}

export default reducer