import React, { Component } from "react";
import { Form, Modal } from "react-bootstrap";
import { withTranslation } from "react-i18next";

 class FlightTimeModal extends Component {
    constructor(props) {
        super(props);
        this.state = {
            flag: false,
            flightTime: { outBound: { arrival: '', departure: '' }, inBound: { arrival: '', departure: '' } },
        };
    }

    componentDidMount() {
        const { flightTime, selectedTab } = this.props;
        this.setState({ flightTime, selectedTab });
    }

    handleSubmit = () => {
        this.props.handleOk(this.state)
    };

    handleFlightTime = (quadrant, type) => {
        const flightType = this.state.selectedTab ? 'inBound' : 'outBound'
        const flightTime = { ...this.state.flightTime, [flightType]: { ...this.state.flightTime[flightType], [type]: quadrant } }
        this.setState({ flightTime });
    };

    handleClear = () => {
        this.setState((state) => ({ ...state, flightTime: { outBound: { arrival: '', departure: '' }, inBound: { arrival: '', departure: '' } } }))
    }

    render() {
        const { show, showModal, segments, filtered_metadata, type, quadrants,t } = this.props;
        return (
            <>
                {/* mobile modal  */}
                <Modal
                    show={show}
                    backdrop="static"
                    keyboard={true}
                    aria-labelledby="contained-modal-title-vcenter"
                    className="d-block d-md-none policymodal  animate-bottom custom-scrollbar "
                    scrollable={true}
                >
                    <Modal.Header>
                        <h4 className="mb-auto col-10  fs-md-bolder  pl-0 px-2">
                            {type}
                        </h4>
                        <div
                            className="justify-content-end fs-medium color-green"
                            onClick={showModal}
                        >
                            {t("Cancel")}
                        </div>
                    </Modal.Header>
                    <Modal.Title className="text-center"></Modal.Title>
                    <Modal.Body className="m-0 p-2">
                        <div className="flights-tab-results d-flex mb-3">
                            {segments?.map((data, i) => {
                                return <div className={`flight-time-tab ${i === this.state.selectedTab ? 'active' : ''}`} key={i} onClick={() => this.setState({ selectedTab: i })}>
                                    <h5><span>{i ? t('Inbound flight') : t("Outbound Flight")}</span></h5>
                                </div>
                            })}
                        </div>
                        <div>
                            <span className="mb-2 d-block">
                                <b>{t("Departs from")} {segments[this.state.selectedTab]?.origin}</b>
                            </span>
                            {Object.entries(quadrants).map((data, index) => {
                                let timeslot1 = data[1][0].split(" ")
                                let timeslot2 = data[1][1].split(" ")
                                return <div className='d-flex justify-content-between mb-2'>
                                    <div className="flight-time">
                                        <input onClick={(e) => this.handleFlightTime(data[0], 'departure')} name='departureRadio' type="radio"
                                            checked={this.state?.flightTime[this.state.selectedTab ? 'inBound' : 'outBound']?.departure === data[0]} />
                                           <span>{timeslot1[0]} {t(timeslot1[1])} - {timeslot2[0]} {t(timeslot2[1])}</span>
                                    </div>
                                    {filtered_metadata &&
                                        <span>{filtered_metadata?.duration_based_count[this.state.selectedTab]?.departure[data[0]]}</span>
                                    }
                                </div>
                            })}
                            <span className="mb-2 d-block">
                                <b>{t("Arrives to")} {segments[this.state.selectedTab]?.destination}</b>
                            </span>
                            {Object.entries(quadrants).map((data, index) => {
                                let timeslot1 = data[1][0].split(" ")
                                let timeslot2 = data[1][1].split(" ")
                                return <div className='d-flex justify-content-between mb-2'>
                                    <div className="flight-time">
                                        <input onClick={(e) => this.handleFlightTime(data[0], 'arrival')} name='arrivalRadio' type="radio"
                                            checked={this.state.flightTime[this.state.selectedTab ? 'inBound' : 'outBound']?.arrival === data[0]}
                                        />
                                        <span>{timeslot1[0]} {t(timeslot1[1])} - {timeslot2[0]} {t(timeslot2[1])}</span>
                                    </div>
                                    {filtered_metadata &&
                                        <span>{filtered_metadata?.duration_based_count[this.state.selectedTab]?.arrival[data[0]]}</span>
                                    }
                                </div>
                            })}
                        </div>
                        <div className="pb-5 px-3 d-flex">
                            <button
                                onClick={() => {
                                    this.handleClear()
                                }}
                                className="btn filterButton2 w-25"
                            >
                                {t("Clear")}
                            </button>
                            <button
                                onClick={() => {
                                    this.handleSubmit()
                                }}
                                className="btn primary-btn ml-3 w-25"
                            >
                                {t("Done")}
                            </button>
                        </div>
                    </Modal.Body>
                </Modal>
            </>
        );
    }
}

export default withTranslation()(FlightTimeModal)