import React, { Component } from 'react';
import ModalWrapper from '../Modals/ModalWrapper';

class ConfirmPopup extends Component {
    render() {
        return ( 
            <ModalWrapper show={ this.props.show}>
                <div 
                    className="p-3 bg-light mx-auto text-center py-md-3 py-3" 
                    style={{width:'40rem'}}> 
                    <br/>
                    <p>Are you sure to want to uncheck it will loss all the data.</p>
                    <div className="d-flex justify-content-end">
                    <button 
                        className="btn  border-color-blue mt-3 mx-2"
                        onClick={()=>{this.props.Close(false)}}>
                        <span className="fw-bold color-blue">Cancel</span>
                    </button>
                    <button 
                        className="btn  border-color-blue mt-3 mx-2"
                        onClick={()=>{this.props.Close(true)}}>
                        <span className="fw-bold color-blue">Ok</span>
                    </button>
                    </div>
                </div>
            </ModalWrapper>
         );
    }
}

export default ConfirmPopup
