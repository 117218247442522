import { FETCH_ORDER_DETAILS_FAILURE, FETCH_ORDER_DETAILS_REQUEST, FETCH_ORDER_DETAILS_SUCCESS, POST_ORDER_CREATION_FAILURE, POST_ORDER_CREATION_REQUEST, POST_ORDER_CREATION_SUCCESS, REMOVE_ORDER } from "./orderTypes"

const initialState = {
    orderDetails:{},
    loading: true,
    error:'',
    errorFlag:false
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case POST_ORDER_CREATION_REQUEST:
            return {
                ...state,
                loading:true
            }
        case POST_ORDER_CREATION_SUCCESS:
            return {
                loading:false,
                orderDetails:action.payload
            }
        case POST_ORDER_CREATION_FAILURE:
            return {
                loading:false,
                error:action.payload,
                errorFlag:true
            }
        case FETCH_ORDER_DETAILS_REQUEST:
            return {
                ...state,
                loading:true,
                errorFlag:false,
                error:''
            }
        case FETCH_ORDER_DETAILS_SUCCESS:
            return {
                loading:false,
                errorFlag:false,
                orderDetails:action.payload,
                error:''
            }
        case FETCH_ORDER_DETAILS_FAILURE:
            return {
                loading:false,
                error:action.payload,
                errorFlag:true
            }
        case REMOVE_ORDER:
            return{
                loading:true,
                orderDetails:{},
                error:''
            }
        default: return state
    }
}

export default reducer;