import { FETCH_PRODUCTS_LIST_FAILURE, FETCH_PRODUCTS_LIST_REQUEST, FETCH_PRODUCTS_LIST_SUCCESS,
    FETCH_PRODUCT_DETAILS_FAILURE, FETCH_PRODUCT_DETAILS_REQUEST, FETCH_PRODUCT_DETAILS_SUCCESS,
    FETCH_VARIANTS_FAILURE, FETCH_VARIANTS_REQUEST, FETCH_VARIANTS_SUCCESS, SORT_MERCHANDISE_CARDS,FETCH_MERCHANDISE_TERMS
} from "./merchandiseTypes"

const initialState = {
    categories:[],
    products:[],
    featuredProducts:[],
    loading: true,
    prodDetails:[],
    prodVariants:[],
    prodImages:[],
    error:'',
    filteredCards : [],
    sortBy:'Default',
    categoryName:'All',
    detailsLoading:true
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_PRODUCTS_LIST_REQUEST:
            return {
                ...state,
                loading:true,
                error:''
            }
        case FETCH_PRODUCTS_LIST_SUCCESS:
            return {
                ...state,
                loading:false,
                categories:action.categories,
                products:action.prodItem,
                featuredProducts:action.featuredProducts,
                error:''
            }
        case FETCH_PRODUCTS_LIST_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
            case FETCH_PRODUCT_DETAILS_REQUEST:
            return {
                ...state,
                detailsLoading:true,
                error:'',
                prodDetails:[],
                prodImages:[],
                prodVariants:[],
            }
        case FETCH_PRODUCT_DETAILS_SUCCESS:
            return {
                ...state,
                detailsLoading:false,
                prodDetails:action.payload.prodDetails,
                prodImages:action.payload.prodImages,
                prodVariants:action.payload.variants,
                error:''
            }
        case FETCH_PRODUCT_DETAILS_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload,
                detailsLoading:false
            }
            case FETCH_VARIANTS_REQUEST:
            return {
                ...state,
                loading:true,
                error:'',
                prodVariants:[],
            }
        case FETCH_VARIANTS_SUCCESS:
            return {
                ...state,
                loading:false,
                prodVariants:action.payload,
                error:''
            }
        case FETCH_VARIANTS_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        case SORT_MERCHANDISE_CARDS:
            return{
                ...state,
                loading:false,
                filteredCards : action.payload.cards,
                sortBy : action.payload.sortBy,
                categoryName:action.payload.categoryName
            } 
            case FETCH_MERCHANDISE_TERMS:
                return {
                    ...state,
                    merchandiseTerms:action.payload.merchandiseTerms,
                }
        default: return state
    }
}

export default reducer;
