import React, { Component } from 'react'
import { Modal } from "react-bootstrap";
import ModalWrapper from '../shared/Modals/ModalWrapper';

export default class Backpopup extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const {cancelModal,confirm} = this.props;
        return (
            <Modal
            show= {true}
            backdrop="static"
            keyboard={true}
            aria-labelledby="contained-modal-title-vcenter"
            centered
            className="d-block backpop"
            scrollable={true}
        >
        <Modal.Header >
            <p className='mb-auto pt-3 col-11 text-center fs-large backpoptit'>Are You Sure?</p>
        </Modal.Header>
        <Modal.Title className="text-center">
        </Modal.Title>
        <Modal.Body className='m-0 p-0'>
            <p className="text-center fs-regular p-2">
                all the entered information will have to be reentered
            </p>
            <div className="d-flex justify-content-center pb-5">
                <button onClick={confirm} className="fs-medium bg-color-green backbtn px-3 py-2 mr-3">Confirm</button>
                <button onClick={cancelModal} className="fs-medium bg-color-green backbtn px-3 py-2 ml-3">Cancel</button>
                
            </div>
        </Modal.Body>
        </Modal>
    //     <ModalWrapper show={ true}>
    //     <div 
    //         className="p-4 bg-light mx-auto text-center py-md-5 py-4" 
    //         style={{width:'40rem'}}> 
    //          <div>
    //         <h3 className='mb-auto pt-3 col-11 text-center fs-large backpoptit'>Are You Sure?</h3>
    //         </div>
    //          <h3 className="text-center fs-regular p-3 text-black-50">
    //              all the entered information will have to be reentered
    //     </h3>
    //         <div className="d-flex justify-content-center ">
    //        <button onClick={confirm} className="fs-medium btn btn-primary bg-color-green backbtn px-3 py-2 mr-3">Confirm</button>
    //          <button onClick={cancelModal} className="fs-medium btn btn-primary bg-color-green backbtn px-3 py-2 ml-3">Cancel</button>   
    //       </div>
    //     </div>
    // </ModalWrapper>
        )
    }
}
