import { FETCH_EXPERIENCE_DETAILS, FETCH_EXPERIENCE_FAILURE, FETCH_EXPERIENCE_IMAGES, FETCH_EXPERIENCE_REQUEST, FETCH_EXPERIENCE_SUCCESS, FETCH_EXPERIENCE_TERMS,FETCH_EXPERIENCE_DETAILS_SUCCESS, FETCH_IMAGES_REQUEST,FETCH_IMAGES_FAILURE } from "./experienceTypes"


const initialState = {
    experienceCards:[],
    loading: true,
    error:'',
    experienceDetails:[],
    experienceTerms:[],
    experienceImages:undefined,
    detailsLoading:true,
    imgLoading:true,
    featuredExpCards:[]
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_EXPERIENCE_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_IMAGES_REQUEST:
            return{
                ...state,
                imgLoading:true,
                experienceImages:undefined
            }
        case FETCH_EXPERIENCE_SUCCESS:
            return {
                ...state,
                loading:false,
                experienceCards:action.payload.data,
                featuredExpCards: action.payload.featuredCards
            }
        case FETCH_EXPERIENCE_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
            case FETCH_EXPERIENCE_DETAILS:
                return{
                    ...state,
                    detailsLoading: true,
                    experienceDetails:[],
                    experienceTerms:[],
                    experienceImages:undefined,
                    error:''
                }
                case FETCH_EXPERIENCE_DETAILS_SUCCESS:
                return{
                    ...state,
                    experienceDetails:action.payload.experienceDetails,
                    detailsLoading: false
                }
        case FETCH_EXPERIENCE_TERMS:
            return{
                ...state,
                experienceTerms:action.payload.experienceTerms,
                
            }
        case FETCH_EXPERIENCE_IMAGES:
            return{
                ...state,
                experienceImages:action.payload.experienceImages,
                imgLoading:false
            }
        case FETCH_IMAGES_FAILURE:
            return{
                ...state,
                imgLoading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;