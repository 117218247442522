import './ProductDetailsImageSelected.css';



export function ProductDetailsImageSelected(props) {
  // const {
  //   image

  // } = props;
  return  <div className="Product-Image-selected" >
  <img src={props.image} alt="alt"/> 
 </div>;
}


export default ProductDetailsImageSelected;
