import {
  FORGET_PASSWORD_REQUEST,
  FORGET_PASSWORD_FAILURE,
  FORGET_PASSWORD_SUCCESS,
} from "./ForgetPassTypes";

const initialState = {
  loading: false,
  message: null,
  sendInstrution: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case FORGET_PASSWORD_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case FORGET_PASSWORD_SUCCESS:
      return {
        loading: false,
        sendInstrution: true,
        message: action.payload,
      };
    case FORGET_PASSWORD_FAILURE:
      return {
        loading: false,
        sendInstrution: false,
        message: action.payload,
      };
    default:
      return state;
  }
};

export default reducer;
