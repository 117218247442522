import React, { Component } from 'react';
import shareTicket from "../../../assests/img/TravelShop/share.png"
import ReactTooltip from "react-tooltip";


export class downloadTicket extends Component {
    constructor(props) {
        super(props);
        this.state = {
            Loading:false
        };
      }


    downloadPDF=async(ticket_url)=> {
          this.setState({Loading:true})
          window.location.href=ticket_url;
          this.setState({Loading:false})
        }

      sharePDF=async(ticket_url)=> {
          if(navigator.canShare){
            navigator.share({
              title: 'Flight Ticket',
              text: "Flight Ticket PDF Link",
              url: ticket_url
            })}}      

  render() {
    const {details, img} = this.props
    return <>
    <div onClick={()=>{this.downloadPDF(details.ticket_url)}} className='d-flex mywalletTravelImages justify-content-center py-2 rounded' data-tip data-for={details?.bookingID}>
    {
        this.state.Loading === true &&
        <div class="spinner-border white" role="status">
        <span class="sr-only">Loading...</span>
        </div>
        ||
        <img loading='lazy' decoding='async' src={img} alt="downloadTicket" />
    }
    <ReactTooltip id={details?.bookingID} place="top" effect="solid" className='p-1'>
        Download Ticket
      </ReactTooltip>
    </div>
      

    <div onClick={()=>{this.sharePDF(details.ticket_url)}} className='d-flex mywalletTravelImages justify-content-center py-2 rounded' data-tip data-for={details?.ticket_url}>
      {  this.state.shareLoading === true &&
        <div class="spinner-border white" role="status">
        <span class="sr-only">Loading...</span>
        </div>
        ||
        <img loading='lazy' decoding='async' src={shareTicket} alt="downloadTicket" />
        }
        <ReactTooltip id={details?.ticket_url} place="top" effect="solid" className='p-1'>
        Share ticket link
      </ReactTooltip>
    </div>
    </>;
  }
}

export default downloadTicket;

// import React,{useState} from 'react';
// import axios from 'axios';
// import { baseUrl } from "../../../utils/prodURL";

// const DownloadTicket = ({details,img}) => {

//     const [loading, setLoading] = useState(false)

//     const downloadPDF=async(bookingID)=> {
//         setLoading(true)
//         const token = localStorage.getItem("userToken");
//         axios.get(`${baseUrl}/travel_shops/download_ticket?booking_id=${bookingID} `,{
//           headers: {
//             'Content-Type': 'application/json',
//             'Authorization': `Bearer ${token}`
//           },
//         })
//         .then((response)=>{
//           let ticketLink = response.data.data.flight_ticket.data; 
//           window.location.href = ticketLink;
//           setLoading(false)
//         })
//       }

//   return <div onClick={()=>{downloadPDF(details.bookingID)}} className='d-flex mywalletTravelImages justify-content-center py-2 rounded'>
//     {
//         loading === true &&
//         <div class="spinner-border white" role="status">
//         <span class="sr-only">Loading...</span>
//         </div>
//         ||
//         <img loading='lazy' decoding='async' src={img} alt="downloadTicket" />
//     }
//     </div>
// };

// export default DownloadTicket;
