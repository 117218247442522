import {Gift_OCCASION_IMAGES_REQUEST,Gift_OCCASION_IMAGES_SUCCESS,Gift_OCCASION_REQUEST, Gift_OCCASION_SUCCESS} from './giftOcassionTypes'

const initialState = {
    loading: true,
    images: [],
    ocassionimage: []
}

const giftOcassionImages = (state = initialState,action) => {
    switch(action.type) {
        case Gift_OCCASION_SUCCESS:{
            return {
                ...state,
                loading: false,
                images: action.payload
            }
        }
        case Gift_OCCASION_REQUEST:{
            return{
                ...state,
                loading:true
            }
        }
        case Gift_OCCASION_IMAGES_REQUEST:{
            return{
                ...state,
                loading:true,
            }
        }
        case Gift_OCCASION_IMAGES_SUCCESS:{
            return{
                ...state,
                ocassionimage: action.payload,
                loading:false,
            }
        }
        default: return state
    }
}

export default giftOcassionImages

