import axios from 'axios';
import secretKeyGenerator from "../utils/serectKeyGenerator";
import { REACT_APP_baseUrl } from './app-constants';


export const applePayHander = (amount, orders, ApplePayFailed, Type) => {

    // var merchantId = "merchant.com.meritincentives.testapimeritmarketplace";
    // Create Session
    const {ApplePaySession} = window;
    var applePaySession = new ApplePaySession(6,{
            countryCode: 'SA',
            currencyCode: 'SAR',
            supportedNetworks: ['visa', 'masterCard', 'amex', 'discover'],
            merchantCapabilities: ['supports3DS'],
            total: { label: 'Hassad Marketplace', amount: amount }
          })

    applePaySession.begin()

    // Valid Session
    applePaySession.onvalidatemerchant =  (event) =>{
        var validation_url = event.validationURL;
        let payload = {
            "payment":{
                "validation_url" : validation_url
            }
        }
        let token = localStorage.getItem("userToken") || ''
        axios.post(`${REACT_APP_baseUrl}/payments/create_apple_pay_session`,
            payload,{
        headers:{
            "Authorization":`Bearer ${token}`
        },
        params:{
            "secret_key":secretKeyGenerator(payload)
        }
    })
        .then((response)=>{
            if(response.data.code === 200){
                let merchant_session = response.data?.data?.merchant_session;
                applePaySession.completeMerchantValidation(merchant_session)
            }
            else{
                ApplePayFailed(response.data?.message)
            }
        })
        .catch((err)=>{
            ApplePayFailed("Something went wrong!! Please try again!!")
        })

        // Payment Authorized
        applePaySession.onpaymentauthorized =  (event) => {
            var applePaymentToken = event.payment.token.paymentData;
            createOrder(applePaymentToken,applePaySession, orders, ApplePayFailed, Type)
            // CreateOrderID - Capture Payment Apple Pay - Get Order - Process Order
          }  
      }
}

const createOrder = (applePaymentToken,applePaySession, orders, ApplePayFailed, Type) => {
    let token = localStorage.getItem("userToken") || ''
    let URL = " "
    let payload = {}
    if (Type === "GIFTCARD") {
        URL = "/orders"
        payload ={"orders":orders}
    }
    else if (Type === "TOPUP") {
        URL = "/orders/create_mobile_topup_order"
        payload = orders
    }
    else if (Type === "OFFERS") {
        URL = "/offer_orders"
        payload = {"orders":orders.orders}
    }
    else if (Type === "MERCHANDISE") {
        URL = "/merchant_product_orders"
        payload = {"orders":orders}
    }
    else if (Type === "EXPERIENCES") {
        URL = "/experience_orders"
        payload = orders
    }
    else if (Type === "SOFTWARE_AND_GAME") {
        URL = "/orders/create_sg_order"
        payload = orders
    }
    
    axios.post(`${REACT_APP_baseUrl}${URL}`,
    payload,{
        headers:{
            "Authorization":`Bearer ${token}`
        },
        params:{
            "secret_key":secretKeyGenerator(payload)
        }
    })
        .then(response  => {
        
            if(response.data.code === 200){
                const orderDetails = response.data.data.order;
                appleCapturePayment(applePaymentToken, orderDetails, applePaySession, ApplePayFailed, Type);
            }
            else{
            applePaySession.completePayment(applePaySession.STATUS_FAILURE);
            ApplePayFailed(response.data?.message)

            }
        })
        .catch(error => {
            applePaySession.completePayment(applePaySession.STATUS_FAILURE);
            ApplePayFailed("Something went wrong!! Please try again!!")

        })
}

const appleCapturePayment = (applePaymentToken, orderDetails, applePaySession, ApplePayFailed, Type) => {

    let URL = " "

    if (Type === "GIFTCARD") {
        URL = "/payment/orders-summary/"
    }
    else if (Type === "TOPUP") {
        URL = "/payment/mobile-topup-summary/"
    }
    else if (Type === "OFFERS") {
        URL = "/payment/offers-orders-summary/"
    }
    else if (Type === "MERCHANDISE") {
        URL = "/payment/merchandise-orders-summary/"
    }
    else if (Type === "EXPERIENCES") {
        URL = "/payment/experience-orders-summary/"
    }
    else if (Type === "SOFTWARE_AND_GAME") {
        URL = "/payment/softwareAndGames-orders-summary/"
    }

    let payload = {
        "payment":{
            "orderid": orderDetails.orderid,
            "type": Type,
            "apple_pay_token": applePaymentToken
        }
    }
    let token = localStorage.getItem("userToken") || ''
    axios.post(`${REACT_APP_baseUrl}/payments/apple_pay`,
    payload,{
        headers:{
            "Authorization":`Bearer ${token}`
        },
        params:{
            "secret_key":secretKeyGenerator(payload)
        }
    })
    .then(response => {
        if(response.data.code===200){
            applePaySession.completePayment(applePaySession.STATUS_SUCCESS);
        window.location.pathname = `${URL}${orderDetails.id}`
            // Process Order
        }
        else{
            applePaySession.completePayment(applePaySession.STATUS_FAILURE);
            ApplePayFailed("Payment Failed")
            // Failure Popup
        }
    })
    .catch((err)=>{
        applePaySession.completePayment(applePaySession.STATUS_FAILURE);
        ApplePayFailed("Something went wrong!! Please try again!!")

    })

}

