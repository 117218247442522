import { FETCH_BANNERS_FAILURE, FETCH_BANNERS_REQUEST, FETCH_BANNERS_SUCCESS,
    FETCH_TAMBOLA_FAILURE, FETCH_TAMBOLA_REQUEST, FETCH_TAMBOLA_SUCCESS } from "./bannerTypes"

const initialState = {
    banners:[],
    bannerloading: true,
    loading:true,
    error:'',
    errorMsg:'',
    tambola:undefined
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_BANNERS_REQUEST:
            return {
                ...state,
                bannerloading:true,
                banners:[]
            }
        case FETCH_BANNERS_SUCCESS:
            return {
                ...state,
                bannerloading:false,
                banners:action.payload
            }
        case FETCH_BANNERS_FAILURE:
            return {
                ...state,
                bannerloading:false,
                error:action.payload
            }
            case FETCH_TAMBOLA_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_TAMBOLA_SUCCESS:
            return {
                ...state,
                loading:false,
                tambola:action.payload
            }
        case FETCH_TAMBOLA_FAILURE:
            return {
                ...state,
                loading:false,
                errorMsg:action.payload
            }
        default: return state
    }
}

export default reducer;