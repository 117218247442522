const Loader = () => {
    return (
      <div className="d-flex justify-content-center pt-2 pb-2">
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </div>
    );
  };

export default Loader