import { FETCH_POINT_BALANCE_FAILURE, FETCH_POINT_BALANCE_REQUEST, FETCH_POINT_BALANCE_SUCCESS,FETCH_POINT_BALANCE_CLEAR } from "./pointTypes"

const initialState = {
    pointBalance:null,
    loading: true,
    error:''
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_POINT_BALANCE_CLEAR:
            return {
                pointBalance:null,
                loading:false
            }
        case FETCH_POINT_BALANCE_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_POINT_BALANCE_SUCCESS:
            return {
                loading:false,
                pointBalance:action.payload
            }
        case FETCH_POINT_BALANCE_FAILURE:
            return {
                loading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;