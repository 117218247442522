
/**
 * Determines whether the current build env is dev. 
 * 
 * @returns {Boolean} Returns the value of NODE_ENV|| REACT_NODE_ENV
 */

const isDev = () => !process.env.NODE_ENV || process.env.NODE_ENV === 'development'


export default isDev