export const FETCH_OFFERS_REQUEST = "FETCH_OFFERS_REQUEST";
export const FETCH_OFFERS_SUCCESS = "FETCH_OFFERS_SUCCESS";
export const FETCH_OFFERS_FAILURE = "FETCH_OFFERS_FAILURE";
export const SORT_OFFERCARDS_BY_CATEGORY = "SORT_OFFERCARDS_BY_CATEGORY";
export const SORT_OFFERCARDS = "SORT_OFFERCARDS";
export const FETCH_OFFER_DETAIL = "FETCH_OFFER_DETAIL";
export const FETCH_TERMS_AND_CONDITIONS = "FETCH_TERMS_AND_CONDITIONS";
export const FETCH_OFFER_DETAIL_SUCCESS = "FETCH_OFFER_DETAIL_SUCCESS";

export const SORT_OFFERCARDS_SUCCESS = "SORT_OFFERCARDS_SUCCESS";
