import React, { Component } from 'react'
import grayTicket from "../../assests/img/TravelShop/grey-plane.svg";
import arrowLeftRight from "../../assests/img/TravelShop/arrow-left-right.svg";
import PlaneTicketBlue from "../../assests/img/TravelShop/plane-ticket-blue.svg";
import { Link } from 'react-router-dom';
import Policy from './Policy';
import { withTranslation } from "react-i18next";
import { connect } from 'react-redux';
import { currancyToPoints, numberWithCommas } from '../../utils/pointsConversion';
import moment from 'moment';


class TravelShopSidebar extends Component {
  constructor(props){
    super(props);
    this.state={
      BaggageModal:false,
      flightSegments:[]
    }
  }
  closeModal=()=> {
    this.setState ({
      BaggageModal:false
    })
}

  flightDetails = () => {
    const flightSegments = this.props.flightDetails?.segments?.reduce((result, segment) => {
      if (segment.segment_indicator === 1) {
        result["outBoundFlight"].push(segment)
      } else {
        result["inBoundFlight"].push(segment)
      }
      return result
    }, { outBoundFlight: [], inBoundFlight: [] })
    this.setState({flightSegments:flightSegments})
  }

  airlinesDetails = (data) => {
    let airlineName = [];
    let duplicateArr = [];
    data?.forEach(e=> {
      if(!duplicateArr.includes(e.airline_name)){
        duplicateArr.push(e.airline_name);
        airlineName.push([e.airline_name,e.airline_image])
      }
    })
    return airlineName
  }

  componentDidMount(){
    this.flightDetails()
    this.airlinesDetails()
  }

  addTimes(segments) {
    const {lang,t} = this.props;
    let duration = 0;
    segments?.forEach(segment => {
      duration = duration + moment.duration(segment.duration).as('milliseconds')
    });
    let time = moment.utc(duration).format("HH:mm")?.split(":");

    if(this.props.lang ==='ar'){
    return `${time[1]} ${t('m')} ${time[0]} ${t('h')}`
    }
    else{
    return `${time[0]} ${t('h')} ${time[1]} ${t('m')}`
    }
   }
  
    render() {
      const {t}= this.props;
      const {passengerCountDetails,flightDetails,searchQueryData,fareDetails} = this.props
      let {price_in_points,booking_fee_in_points} = flightDetails?.fare_local || {}
      const {outBoundFlight,inBoundFlight} = this.state.flightSegments || []
  


      
  
        return (
           <div className='mb-2'>
             {/* <Policy
                showModal = {this.closeModal}
                style={{opacity: ".5 !important"}}
                show = {this.state.BaggageModal}
                title="Baggage & Ticket Policies"
                content=" Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident reprehenderit vel voluptatem nam assumenda corrupti asperiores pariatur veniam ut aLorem ipsum, dolor sit amet consectetur adipisicing elit. Provident reprehenderit vel voluptatem nam assumenda corrupti asperiores pariatur veniam ut a.
                Lorem ipsum, dolor sit amet consectetur adipisicing elit. Provident reprehenderit vel voluptatem nam assumenda corrupti asperiores pariatur veniam ut aLorem ipsum, dolor sit amet consectetur adipisicing elit. Provident reprehenderit vel voluptatem nam assumenda corrupti asperiores pariatur veniam ut a.
                "
                /> */}
               <div className="d-flex justify-content-center px-2 py-3 flex-column bg-white travelBoxShadow mb-2">
                   <div className="d-flex gap-3 justify-content-center flex-column align-items-center">
                   <img loading='lazy' decoding='async' src={PlaneTicketBlue} alt="Plane Ticket Blue" />
                   <div className="d-flex color-blue">
                          <div className="px-2 fw-bold">
                            <h5>{outBoundFlight?.[0]?.origin?.city_code}</h5>
                          </div>
                          <div>
                            <img loading='lazy' decoding='async' src={arrowLeftRight} alt="arrowLeftRight" width="100%" height="40%" />
                          </div>
                          <div className="px-2 fw-bold ">
                            <h5>{outBoundFlight?.[outBoundFlight.length - 1]?.destination?.city_code}</h5>
                          </div>
                    </div>
                    <div className='text-muted'>
                        <p> {moment(searchQueryData?.startDate).format('MMM Do')} {searchQueryData?.endDate && " - "} {searchQueryData?.endDate && moment(searchQueryData?.endDate).format('MMM Do')} {" | "} {flightDetails?.passenger_count} {t("Passenger")}{" "} {" | "} {t(flightDetails?.segments?.[0]?.cabin_class)}</p>
                    </div>
                   </div>
                    <hr className='hrLine'/>
                    <div className="d-flex flex-column gap-1">
                            <div className="d-flex justify-content-between w-100">
                                <div className="d-flex justify-content-center align-items-center">
                                  <div className="px-2 fw-bold">
                                      <span>{outBoundFlight?.[0]?.origin?.city_code}</span>
                                    </div>
                                    <div>
                                      <img loading='lazy' decoding='async' src={grayTicket} alt="" width="100%" height="40%"/>
                                    </div>
                                    <div className="px-2 fw-bold">
                                      <span>{outBoundFlight?.[outBoundFlight.length - 1]?.destination?.city_code}</span>
                                    </div>
                                </div>
                                <div>
                                  </div>
                                </div>
                                <div className='d-flex px-2'>
                                  {
                                    this.airlinesDetails(outBoundFlight)?.map((e,index) => (
                                      <div className="d-flex align-items-center" key={index}>
                                          <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                          <span className='text-muted px-2'>{e[0]}</span>
                                      </div>
                                    ))
                                  }
                            </div>
                            <div className="d-flex fw-bold w-100 justify-content-between px-2">
                                <div className="">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(outBoundFlight?.[outBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {outBoundFlight?.length - 1 > 0 && <sup>+{outBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column">
                                  <span>{moment(outBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>{t('Duration')} {this.addTimes(outBoundFlight)}</small>
                                </div>
                            </div>
                  </div>
                  {searchQueryData?.round_trip_flag && <hr className='hrLine'/>}
                  {
                    searchQueryData?.round_trip_flag && <div className="d-flex flex-column gap-1">
                    <div className="d-flex justify-content-between w-100">
                    <div className="d-flex justify-content-center align-items-center">
                                  <div className="px-2 fw-bold">
                                      <span>{inBoundFlight?.[0]?.origin?.city_code}</span>
                                    </div>
                                    <div>
                                      <img loading='lazy' decoding='async' src={grayTicket} alt="" width="100%" height="40%"/>
                                    </div>
                                    <div className="px-2 fw-bold">
                                      <span>{inBoundFlight?.[inBoundFlight.length - 1]?.destination?.city_code}</span>
                                    </div>
                                </div>
                        <div>
                          </div>
                        </div>
                        <div className='d-flex'>
                                  {
                                    this.airlinesDetails(inBoundFlight)?.map((e,index) => (
                                      <div className="d-flex align-items-center" key={index}>
                                          <span><img loading='lazy' decoding='async' height={'25px'} width={'25px'} src={e[1]} alt="flight" /></span>
                                          <span className='text-muted px-2'>{e[0]}</span>
                                      </div>
                                    ))
                                  }
                            </div>
                            <div className="d-flex fw-bold w-100 justify-content-between px-2">
                                <div className="">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('HH:mm')}</span>-<span>{moment(inBoundFlight?.[inBoundFlight.length - 1]?.arrival_time).format('HH:mm')}</span> {inBoundFlight?.length - 1 > 0 && <sup>+{inBoundFlight?.length - 1}</sup>}
                                </div>
                                <div className="">|</div>
                                <div className="d-flex flex-column">
                                  <span>{moment(inBoundFlight?.[0]?.departure_time).format('MMM Do YYYY')}</span>
                                  <small className='text-muted'>{t('Duration')} {this.addTimes(inBoundFlight)}</small>
                                </div>
                            </div>
          </div>
                  }
                  <hr className='hrLine'/>
                  {/* Flight Rules */}
                  {!!flightDetails?.fare_rules?.[0]?.details && <ul className='list-unstyled overflow-auto custom-scrollbar' style={{height:140}}>

                    {
                       flightDetails?.fare_rules?.map((val,ind)=>{
                        return(
                          <>
                                      <div className='d-flex' key={ind}>
                                        <span className='fw-bold'>{t("Flight Number")}</span> 
                                        <span className='text-bold ml-2'> - {val.flight_number}</span> 
                                        <span className='fw-bold ml-2'> {`(${val.origin} - ${val.destination})`}</span>
                                      </div>
                              {
                                
                                val.details?.endsWith("\n") && val.details?.substring(0, val.details?.length - 2)?.split("\n")?.map((val2)=>{
                                  return(
                                    <>
                                      <li className='text-muted small'>● {val2}</li>
                                    </>
                                  )
                                })  
                              }
                              {
                                !val.details?.endsWith("\n") && val.details?.split("\n")?.map((val3)=>{
                                  return(
                                    <>
                                      <li className='text-muted small'>● {val3}</li>
                                    </>
                                  )
                                })
                              }
                          </>
                        )
                      })
                    }
                    </ul>
                  }
                  {/* <button className='btn color-green d-flex' onClick={()=>{this.setState({BaggageModal:true})}}>{t("Baggage & Ticket Policies")}</button>        */}

               </div>

               {/* Points Breakdown */}
               <div className="d-flex justify-content-center px-2 py-3 flex-column bg-white travelBoxShadow gap-1">
                 <h6>{t("Points Breakdown")}</h6>
                 {
                   fareDetails && fareDetails?.map(fareData =>(
                    <>
                    <div className="d-flex justify-content-between">
                    {
                      fareData.passenger_type === 1 && <span className='fw-bold'>{t("Adult")}</span>
                    }
                    {
                      fareData.passenger_type === 2 && <span className='fw-bold'>{t("Child")}</span>
                    }
                    {
                      fareData.passenger_type === 3 && <span className='fw-bold'>{t("Infant")}</span>
                    }
                    <span className='fw-bold'>{numberWithCommas(fareData.price_in_points)} {t("Points")}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='text-muted'>{t("Base Fare")}</span>
                    <span className='text-muted'>{numberWithCommas(fareData.base_fare_in_points)} {t("Points")}</span>
                  </div>
                  <div className="d-flex justify-content-between">
                    <span className='text-muted'>{t("Tax and Fees")}</span>
                    <span className='text-muted'>{numberWithCommas(fareData.tax_in_points)} {t("Points")}</span>
                  </div>
                    </>
                   ))
                 }
                 
                 
                 <div className="d-flex justify-content-between">
                   <span className='fw-bold'>{t("Booking fee is inclusive of VAT")}</span>
                   <span className='fw-bold'>{numberWithCommas(booking_fee_in_points)} {t("Points")}</span>
                 </div>
                 <hr className='hrLine'/>
                <div className="d-flex justify-content-between">
                  <span className='fw-bold'>{t("Total")}</span>
                   <span className='fw-bold color-red'>{numberWithCommas(price_in_points)} {t("Points")}</span>
                </div>
               </div>
           </div>
        )
    }
}
const mapStateToProps = (state) => {
  return {
    passengerCountDetails:state.travel.passengerCountDetails,
    flightDetails:state.travel.selectedFlightDetail?.search?.data,
    searchQueryData:state.travel.searchQueryData,
    fareDetails:state.travel.selectedFlightDetail?.search?.data?.fare_breakdown_local
  };
};

export default connect(mapStateToProps)(withTranslation()(TravelShopSidebar));

