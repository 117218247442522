import React, { Component } from "react";
import { ReactComponent as LeftIcon } from '../../assests/img/TravelShop/Left-arrow.svg'
import { ReactComponent as DirectionIcon } from '../../assests/img/TravelShop/arrow-left-right.svg'
import { ReactComponent as EditButton } from "../../assests/img/TravelShop/editButton.svg";
import { withRouter } from "react-router";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import moment from "moment";

class TravelDetailTopBar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      toggle: {
        first: true,
        second: true,
        third: true,
        fourth: true,
      },
    };
  }
  render() {
    const { searchQueryData } = this.props

    return (
      <>
        <div className="search-travel-info">
          <LeftIcon onClick={() => this.props.handleSearchForm ? this.props.handleSearchForm(true) : this.props.history.push('/flights')} />
          <div className="search-travel-detail">
            <span>{searchQueryData?.travel_origin_code}</span><DirectionIcon /><span>{searchQueryData?.travel_destination_code}</span>
            <p>{`${moment(searchQueryData?.startDate).format('DD MMM')} `}{searchQueryData?.endDate ? `- ${moment(searchQueryData?.endDate).format('DD MMM')} ` : ''}{searchQueryData?.passangerCount ? `| ${searchQueryData?.passangerCount} per ` : ''}{searchQueryData?.passengerClass ? `| ${searchQueryData?.passengerClass}` : ''}</p>
          </div>
          {this.props.children}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  return {
    searchQueryData: state.travel?.searchQueryData,
  }
}

export default connect(mapStateToProps)(withTranslation()(withRouter(TravelDetailTopBar)));
