import {Gift_Submit_Details} from './giftFlowTypes'

export const fetchGiftFlowData = data => {
    console.log("yes yes", data);
    return {
        type:Gift_Submit_Details,
        payload:data
    }
}


