import { FETCH_COUNTRYCODE_FAILURE, FETCH_COUNTRYCODE_REQUEST, FETCH_COUNTRYCODE_SUCCESS,
    FETCH_PHONEFORMAT_FAILURE, FETCH_PHONEFORMAT_REQUEST, FETCH_PHONEFORMAT_SUCCESS 
} from "./CountryCodeTypes"

const initialState = {
    countryCode:[],
    countryCodeloading: true,
    PhoneFormatloading:true,
    error:'',
    PhoneFormat:{}
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_COUNTRYCODE_REQUEST:
            return {
                ...state,
                countryCodeloading:true
            }
        case FETCH_COUNTRYCODE_SUCCESS:
            return {
                ...state,
                countryCodeloading:false,
                countryCode:action.payload
            }
        case FETCH_COUNTRYCODE_FAILURE:
            return {
                ...state,
                countryCodeloading:false,
                error:action.payload
            }
        case FETCH_PHONEFORMAT_REQUEST:
            return {
                ...state,
                PhoneFormatloading:true
            }
        case FETCH_PHONEFORMAT_SUCCESS:
            return {
                ...state,
                PhoneFormatloading:false,
                PhoneFormat:action.payload
            }
        case FETCH_PHONEFORMAT_FAILURE:
            return {
                ...state,
                PhoneFormatloading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;