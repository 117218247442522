import { FETCH_GAMES_DETAILS_FAILURE, FETCH_GAMES_DETAILS_REQUEST, FETCH_GAMES_DETAILS_SUCCESS, FETCH_GAMES_FAILURE, FETCH_GAMES_REQUEST, FETCH_GAMES_SUCCESS, FETCH_GAMES_TERMS_SUCCESS } from "./GamesTypes"

const initialState = {
    gamesCards:[],
    featuredCards:[],
    loading: true,
    error:'',
    gameDetails:[],
    gameTerms:[],
    detailsLoading:true,
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_GAMES_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_GAMES_SUCCESS:
            return {
                ...state,
                loading:false,
                gamesCards:action.payload.data,
                featuredCards:action.payload.featuredProducts
            }
        case FETCH_GAMES_FAILURE:
            return {
                loading:false,
                error:action.payload
            }
        case FETCH_GAMES_DETAILS_REQUEST:
            return{
                    ...state,
                    detailsLoading: true,
                    gameDetails:[],
                    gameTerms:[],
                    error:''
                }
        case FETCH_GAMES_DETAILS_SUCCESS:
            return{
                    ...state,
                    gameDetails:action.payload,
                    detailsLoading: false
                }
        case FETCH_GAMES_DETAILS_FAILURE:
            return{
                ...state,
                detailsLoading: false,
                error:action.payload
            }
        case FETCH_GAMES_TERMS_SUCCESS:
            return{
                ...state,
                gameTerms:action.payload 
            }
        default: return state
    }
   
}

export default reducer;