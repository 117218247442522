import React, { Component } from 'react';
import { connect } from 'react-redux';
// import ProductTable from '../ProductTable';
// import SearchBar from '../SearchBar';
import { withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

class Receivedgifts extends Component {
    constructor(props) {
        super(props);
        this.state = { 
            searchFilter:"",
            dropdownFilter:"ALL",
            isLoaded:true
         }
    }
    headers=["Product","Description","Status", "Quantity"]
    // setDropdownFilter = (val) =>{this.setState({dropdownFilter:val})}
    // setFilter=(val)=>{this.setState({searchFilter:val})}
    // searchFilter=()=>{
    //    return  this.props.mobileTopUpOrders
    //             .filter(em=>
    //                 (em.brand_name.match(new RegExp(`${this.state.searchFilter.replace(/[^\w\s]/gi, "")}`,"ig"))
    //                 &&
    //                 (this.state.dropdownFilter==="ALL"?
    //                     true:
    //                     this.state.dropdownFilter===em.order_status)
    //                 ))
    // }
    componentDidMount(){
        // setTimeout(() =>
        // window.scrollTo({
        //     top: 0,
        //     behavior: "smooth"
        // }), 800);
        // const url = "https://run.mocky.io/v3/29a7f7e1-faa5-43b9-912d-91479429666a"
        // fetch the search autocomplete list and change the state
        // fetch(url, {
        //     method: 'GET',
        //     headers: {
        //         'Accept': 'application/json',
        //         'Content-Type': 'application/json',
        //     }
        // }).then((response) => {
        //     response.json().then((resp) => {
        //         const ReceivedGifts = resp.data.giftcards;
        //         this.setState({
        //                 ReceivedGifts,
        //                 isLoaded:false
        //             })
        //         })
        //     }
        // )
    }
    render() {
        setTimeout(() => {this.setState({isLoaded: false})}, 100);
        let lang = this.props.i18n.language
        const{t}= this.props
        // const {dropdownFilter} = this.state;
        const linkCards = (type,row) =>{
            if(row.giftcard_token){
                return (

                    <>    
                               <Link
                               to={{
                                  pathname:`/giftcard/${row.giftcard_token}/details?lang=${lang}`,
                                  state: {...row }
                              }}
                              target='_blank'
                              ><td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' >
                              <p className='m-0'  style={{fontSize:"15px"}}>01</p>
                          <h6 className='m-0 fw-bold mb-1 '  style={{fontSize:"12px", color:"#00008b"}}>{t("View")}</h6>
                  </button> </td></Link>
                             

                    </>                 
                )
            }
            else if(row.voucher_url){
                return (
                    <a style={{textDecoration:"none"}}
                    href= { row.voucher_url}
                    target="_blank"
                    rel="noreferrer"
                    >     
                            <td className="px-1 py-4 align-bottom  "><button className='bg-light-gray border-0 px-3  fw-bold view-button' ><p className='m-0'  style={{fontSize:"15px"}}>01</p>
                            <h6 className='m-0 fw-bold mb-1 '  style={{fontSize:"11px", color:"#00008b"}}>{t("View")}</h6>
                    </button> </td>
                       
                    </a>
                )
            } 
        }
        return ( 
           <>
           {
               this.state.isLoaded ?
               <section>
                     <div className="d-flex justify-content-center pt-4 pb-4 mt-4 mb-4">
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                    </div>
               </section>
               :
               <div>
               {/* <SearchBar 
                   sectionName="offers"
                   onChange={this.setFilter} 
                   dropdownFilter={dropdownFilter} 
                   setDropdownFilter={this.setDropdownFilter}
                   dropdownItems = {["ALL","CONFIRMED","FULFILLED","REFUNDED","CANCELED"]}
               /> */}
             <div className="p-4 d-md-block d-none">
            
                  <table className="table table-borderless">
                  {/* <thead className="border-bottom">
                      <tr>
                          {this.headers.map(header=>(
                              <th 
                                  className="text-muted fw-bold px-0"
                                  scope="col"
                                  key={header}>
                                  {t(header)}
                              </th>
                          ))}
                      </tr>
                  </thead> */}
                  <tbody>
            
                  <>
                      {this.props.receivedgiftsOrders?.length !==0 &&  this.props.receivedgiftsOrders?.map(row=>(
                          <tr className="border-bottom fw-bold text-black-50" key={row.id} >
                              <td className="px-0 py-4" width="200">
                                  <div className=" bg-color-gray d-flex align-items-center justify-content-center" style={{width:'9rem',height:'9rem'}}>
                                      <img 
                                          className="d-block img-fluid"
                                          style={{maxWidth:'9rem',maxHeight:'9rem'}}
                                      src={row.card_images?.small_rectangle || row.image?.small_rectangle} alt="" />
                                  </div>
                                  
                              </td>
                              <td className="px-0 py-4">
                                  <h5 className="color-blue m-0">{lang=== 'en'? row.brand_name: row.brand_name_arabic}</h5>
                                  <div className="pt-1 pb-3"> {t("by")} { lang === 'en'? row.brand_name : row.brand_name_arabic }</div>
                                  <div><span>{`${t("Gift Card Number")}:`} </span><span className="color-skyblue">{row.giftcard_number}</span></div>
                                  <div><span>{`${t("Expiry Date")}:`} </span><span className="text-dark">{new Date(row.expiration_date).toDateString()}</span>
                                  <div className='pt-3'>
                                 {
                                     row.gift_message !== null?
                                     <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize">
                                     {row.gift_message}
                                 </button>:
                                 false
                                 }
                                  </div>
                                  </div>

                              </td>
                              <td className="px-0 py-4">
                                  <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize">
                                      {t("RECEIVED")}
                                  </button>
                              </td>
                             {
                                 row.order_status ==='FULFILLED'?
                                 <>
                                   { linkCards(this.props.type,row)}
                                 </>
                                 :
                                 false
                             }
                          </tr>
                      ))}
                      {
                          this.props.receiveOffersOrder?.length !==0 ?
                          <>
                            {this.props.receiveOffersOrder?.map(row=>(
                                <tr className="border-bottom fw-bold text-black-50" key={row.id}>
                              <td className="px-0 py-4" width="200">
                                  <div className=" bg-color-gray d-flex align-items-center justify-content-center" style={{width:'9rem',height:'9rem'}}>
                                      <img 
                                          className="d-block img-fluid"
                                          style={{maxWidth:'9rem',maxHeight:'9rem'}}
                                      src={row.images?.small_rectangle} alt="" />
                                  </div>
                                  
                              </td>
                              <td className="px-0 py-4">
                                  <h5 className="color-blue m-0">{lang=== 'en'? row?.brand_name: row?.brand_name_arabic}</h5>
                                  <div className="pt-1 pb-3"> {t("by")} { lang === 'en'? row.brand_name : row.brand_name_arabic }</div>
                                  <div><span>{`${t("Voucher Number")}:`} </span><span className="color-skyblue">{row?.voucher_number}</span></div>
                                  <div><span>{`${t("Expiry Date")}:`} </span><span className="text-dark">{new Date(row.effective_to_date).toDateString()}</span>
                                  <div className='pt-3'>
                                 {
                                     row.gift_message !== null?
                                     <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize">
                                     {row?.gift_message}
                                 </button>:
                                 false
                                 }
                                  </div>
                                  </div>

                              </td>
                              <td className="px-0 py-4">
                                  <button className="bg-light-gray border-0 px-3 py-2 fw-bold text-muted text-capitalize">
                                      {t("RECEIVED")}
                                  </button>
                              </td>
                             {
                                 row.order_status ==='FULFILLED'?
                                 <>
                                   { linkCards(this.props.type,row)}
                                 </>
                                 :
                                 false
                             }
                          </tr>
                           ))}
                          </>
                          : false
                      }
                      </>
                  </tbody>
              </table>
            
           </div>
           </div>
         } 
           </>
         );
    }
}
 
const mapStateToProps = state => {
    return {
        receivedgiftsOrders: state.myWallet.receivedGifts,
        receiveOffersOrder:state.myWallet.receivedOffer,
    }
}

export default connect(mapStateToProps)(withTranslation()(Receivedgifts));