import { FETCH_TERMSCONDS_FAILURE, FETCH_TERMSCONDS_REQUEST, FETCH_TERMSCONDS_SUCCESS } from "./TermsTypes"

const initialState = {
    loading:true,
    error:'',
    termsConds:{}
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_TERMSCONDS_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_TERMSCONDS_SUCCESS:
            return {
                ...state,
                loading:false,
                termsConds:action.payload
            }
        case FETCH_TERMSCONDS_FAILURE:
            return {
                ...state,
                loading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer;