import './Line.css';



export function Line(props) {
  return  <div className="pt-line" >
 </div>;
}


export default Line;
