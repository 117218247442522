import React, { Component } from 'react'
import { withRouter } from 'react-router-dom';
import Breadcrumbs from '../shared/Breadcrumbs'
import TravelShopSidebar from './TravelShopSidebar';
import arrowImg from '../../assests/img/TravelShop/sidearrow.png'
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";


class TravelShopHOC extends Component {
    constructor(props){
        super(props);
        this.state={
            
        }
    }

    componentDidMount(){
       !this.props.stopRedirection && window.addEventListener('beforeunload', this.handleLeavePage);
    }

    componentWillUnmount() {
        !this.props.stopRedirection && window.removeEventListener('beforeunload', this.handleLeavePage);
    }
    handleLeavePage(e) {
        e.returnValue = this.handleBlockedNavigation;
        return e.returnValue;
      }

    render() {
        const breadcrumbs =[
            {name:("Home"),url:'/',active:true},   
            {name:("Travel Shop"),url:`/flights`,active:false}
        ] 
        const {childComponent,history ,t } = this.props;
        return (
            <>
                <Breadcrumbs crumbs={breadcrumbs}/>
                <div className={`${this.props.location.pathname !== '/travel-details' && 'footerMargin'} row`}>
                    <div className="py-2 d-none d-md-block">
                        <div className="border mx-4 d-none d-md-block d-flex justify-content-center align-items-center" onClick={history.goBack}>
                        <img loading='lazy' decoding='async' src={arrowImg} alt="arrow button" />
                        <button className='btn m-0 p-2'>{t("Back")}</button>
                        </div>
                    </div>
                    <div className="bg-flightGray d-flex col-12 pl-md-4 pt-md-4 pr-md-4 pb-md-1 p-0 ">
                        <div className="col-3 d-none d-md-block">
                        {!this.props.location.pathname?.startsWith('/payment/travel-orders-summary') && <TravelShopSidebar lang={this.props.lang}/>}
                        </div>
                        <div className="col-md-9 col-sm-12 ">
                        <div className="maincheck pb-3">
                                <div className= {` ${
                                this.props.lang === "ar" ? "check-arabic" : "check"
                              } `}>
                                    <div className=
                                     {`${this.props.location.pathname?.startsWith('/payment/travel-orders-summary') ?'maininput normalcheck' :'maininput bigcheck'} `}
                                ></div>
                                    <p className="mb-auto checkname fs-regular mx-2">{t("Customer Information")}</p>
                                </div>
                                <div className= {`checkwidth ${
                                this.props.lang === "ar" ? "check-arabic" : "check"
                              } `}
                                >
                                    <div className="maininput normalcheck"></div>
                                    <p className="mb-auto checkname fs-regular mx-2">{t("Payment")}</p>
                                </div>
                                <div className={`check1 ${
                                this.props.lang === "ar" ? "check-arabic" : "check"
                              } `}>
                                    <div className=
                                    {`${this.props.location.pathname?.startsWith('/payment/travel-orders-summary') ?'maininput bigcheck' :'maininput'} `}
                                    ></div>
                                    <p className="mb-auto checkname fs-regular mx-2">{t("Booking Confirmation")} </p>
                                </div>
                            </div>
                            {childComponent}
                        </div>
                    </div>
                </div>
            </>
        )
    }
}

export default withRouter(withTranslation() (TravelShopHOC));


