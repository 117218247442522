import { FETCH_POINTEXCHANGE_FAILURE, FETCH_POINTEXCHANGE_REQUEST, FETCH_POINTEXCHANGE_SUCCESS } from "./pointExchangeTypes"



const initialState = {
    pointExchangeCards:[],
    loading: true,
    error:''
}

const reducer = (state = initialState,action) => {
    switch(action.type){
        case FETCH_POINTEXCHANGE_REQUEST:
            return {
                ...state,
                loading:true
            }
        case FETCH_POINTEXCHANGE_SUCCESS:
            return {
                loading:false,
                pointExchangeCards:action.payload
            }
        case FETCH_POINTEXCHANGE_FAILURE:
            return {
                loading:false,
                error:action.payload
            }
        default: return state
    }
}

export default reducer