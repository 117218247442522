import React, { Component } from 'react';

class ModalWrapper extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }
    render() { 
        return ( 
            this.props.show?
            <div 
                className="position-fixed modal-backdrop top-0 d-flex justify-content-center align-items-center overflow-auto" style={{zIndex:'1400'}}>
                {this.props.children}
            </div>
            :null
         );
    }
}
 
export default ModalWrapper;